<div>
  <div class="projects">
    @for (project of projects; track $index) {
      @let start_date = project.start_date ? (project.start_date | date: 'MM/dd/yyyy') : '--/--/----';
      @let end_date = project.end_date ? (project.end_date | date: 'MM/dd/yyyy') : '--/--/----';

      <div [class]="['project-item', project.status]" [class.project-item--disabled]="disabled" (click)="showPopover(project, op, $event)">
        {{ project.type }}: <div class="project-item__date"><span>Start: {{start_date}}</span> <span>End: {{end_date}}</span></div>

        @if (project.status === 'active' && start_date === '--/--/----') {
          <div [pTooltip]="disabled ? 'Before selecting you need to set Client Name' : 'No start date set'" tooltipPosition="top" class="warning-badge">!</div>
        }

        @if (project.status === 'archived' && end_date === '--/--/----') {
          <div [pTooltip]="disabled ? 'Before selecting you need to set Client Name' : 'No end date set'" tooltipPosition="top" class="warning-badge">!</div>
        }
      </div>
    }
  </div>
</div>

<p-popover #op [styleClass]="'projects-date-overlay'">
    <div class="projects-date-overlay__item">
      <label for="buttondisplay">Start Date</label>
      <p-datepicker
        [(ngModel)]="selectedProject.start_date"
        [dateFormat]="'mm/dd/yy'"
        [dataType]="'string'"
        [placeholder]="'mm/dd/yyyy'"
        [showIcon]="true"
        [autofocus]="false"
        [readonlyInput]="true"
        [showButtonBar]="true"
        (onClearClick)="onCheckType('start_date', true)"
        (onSelect)="onCheckType('start_date')"
        (onInput)="onCheckType('start_date')"
        [appendTo]="'body'"
        inputId="buttondisplay"
        [showOnFocus]="true" />
      <div class="date-label-item"><b>First paid invoice date:</b> <span (click)="onClickFirstDateInvoice()">{{selectedProject.first_invoice_date | date: 'MM/dd/yyyy'}}</span></div>
    </div>
  <div class="projects-date-overlay__item">
    <label for="buttondisplay">End Date</label>
    <p-datepicker
      [(ngModel)]="selectedProject.end_date"
      [dateFormat]="'mm/dd/yy'"
      [dataType]="'string'"
      [placeholder]="'mm/dd/yyyy'"
      [showIcon]="true"
      [autofocus]="false"
      [readonlyInput]="true"
      [showButtonBar]="true"
      (onClearClick)="onCheckType('end_date', true)"
      (onSelect)="onCheckType('end_date')"
      (onInput)="onCheckType('end_date')"
      [appendTo]="'body'"
      inputId="buttondisplay"
      [showOnFocus]="true" />
    <div class="date-label-item"><b>Last paid invoice date:</b> <span (click)="onClickLastDateInvoice()">{{selectedProject.last_invoice_date | date: 'MM/dd/yyyy'}}</span></div>
    @if (selectedProject.type === 'DEV' && selectedProject.warranty_expiration_date) {
      <div class="date-label-item"><b>Warranty expiration date:</b> {{selectedProject.warranty_expiration_date | date: 'MM/dd/yyyy'}}</div>
    } @else if (selectedProject.type === 'DEV' && !selectedProject.warranty_expiration_date) {
<!--      <div class="date-label-item"><b>Warranty expiration date:</b> &#45;&#45;/&#45;&#45;/&#45;&#45;&#45;&#45;</div>-->
    }

    @if (selectedProject.reconciliation) {
      <div class="date-label-item"><b>Reconciliation:</b> ${{selectedProject.reconciliation.toLocaleString('en', { maximumFractionDigits: 2 })}}</div>
    }
  </div>

  <div class="case-value-item__buttons">
    <app-button
      label="Save"
      (click)="save()"
      [style]="{'width': '100%', 'margin-top': '5px', 'border-color': '#7986FF'}"
    />
  </div>
</p-popover>
