<div class="goal-item">
  <span [class.goal-item__red]="goals?.until_the_annual_report === 0" [class.goal-item__yellow]="goals?.until_the_annual_report === 1">Until the Annual Report: <b>{{ goals?.until_the_annual_report || '--' }}</b> months</span>
</div>
<div class="goal-item">
  <span>Target: <b>{{ goals?.target ? goals?.target.toLocaleString('en', { maximumFractionDigits: 2 }) : '--' }}</b> MQLs per month</span>
</div>

@if (requiredField) {
  <div pTooltip="Goals are not set properly" tooltipPosition="top" class="warning-badge">!</div>
}
