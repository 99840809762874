import { Component, inject } from '@angular/core';
import { AgGridAngular, ICellRendererAngularComp } from 'ag-grid-angular';
import {
  AllCommunityModule,
  ColDef, GetRowIdFunc, GetRowIdParams,
  GridApi,
  GridReadyEvent,
  ICellRendererParams,
  ModuleRegistry, RowClassRules, RowSelectionOptions,
} from 'ag-grid-community';
import { Badge } from 'primeng/badge';
import { Popover } from 'primeng/popover';
import { TableModule } from 'primeng/table';
import { Button } from 'primeng/button';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AllEnterpriseModule } from 'ag-grid-enterprise';
import { AgChartsCommunityModule } from 'ag-charts-enterprise';
import { CommentCellRendererComponent } from '../comment-cell-renderer/comment-cell-renderer.component';
import { ViewCellRenderComponent } from '../view-cell-render/view-cell-render.component';
import { StorageService } from '../../../../services/storage.service';
import { Tooltip } from 'primeng/tooltip';
import {
  OverviewFormsSearchActivitiesCellRendererComponent
} from '../overview-forms-search-activities-cell-renderer/overview-forms-search-activities-cell-renderer.component';
import { ApiService } from '../../../../services/api.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ConfirmDialog } from 'primeng/confirmdialog';
import { LeadDetailsDialogComponent } from '../../../dialog/lead-details-dialog/lead-details-dialog.component';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import {
  OverviewFormsUpdateDialogComponent
} from '../overview-forms-update-dialog/overview-forms-update-dialog.component';

ModuleRegistry.registerModules([AllCommunityModule]);

@Component({
  selector: 'app-overview-forms-renderer',
  imports: [ConfirmDialog, Popover, TableModule, Button, AgGridAngular, Tooltip],
  templateUrl: './overview-forms-renderer.component.html',
  styleUrl: './overview-forms-renderer.component.scss',
  providers: [ConfirmationService, DialogService],
})
export class OverviewFormsRendererComponent implements ICellRendererAngularComp {
  private http = inject(HttpClient);
  private dialogService: DialogService = inject(DialogService);
  public messageService: MessageService = inject(MessageService);
  public apiService: ApiService = inject(ApiService);
  public storageService: StorageService = inject(StorageService);
  protected params: any = {};
  protected total: number = 0;
  protected forms: any = [];
  protected problems: any = [];
  protected exceptions: any = [];
  protected loading: boolean = false;
  private gridApi: GridApi | undefined;
  public dismissable: boolean = true;
  public selectedRowsCount: number = 0;

  updateDialogRef: DynamicDialogRef | undefined;

  public rowIsSelected: boolean = false;

  private selectedFormType: string = '';
  private application: string = '';

  public isActivitiesGrid: boolean = false;

  private platform_id: string = '';
  form_id: string = '';

  public rowClassRules: RowClassRules = {
    row_is_blocked: (params) => {
      return params.data.row_is_blocked;
    },
  };

  rowSelection: RowSelectionOptions | "single" | "multiple" = {
    enableClickSelection: true,
    mode: 'singleRow',
    hideDisabledCheckboxes: true,
    checkboxes: false,
    isRowSelectable: (params) => this.isActivitiesGrid,
  };

  public show: boolean = false;

  rowData = [];

  public defaultColDef: ColDef = {
    suppressHeaderMenuButton: true,
    suppressMovable: true,
    flex: 1
  };

  colDefs: ColDef[] = [];

  constructor(private confirmationService: ConfirmationService) {
    this.storageService.$searchActivitiesActionCLick.pipe(takeUntilDestroyed()).subscribe((data: any) => {
      if (data && data.platform_id === this.platform_id) {
        this.selectedRowsCount = 0;
        this.application = data.application;
        this.getActivities();
      }
    });
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.form_id = this.params.data.id;
    this.platform_id = this.params.data.platform_id;

    this.setDefaultColDefs();

    this.refresh(params);
  }

  public selectionChanged() {
    const ff = this.gridApi?.getSelectedRows();
    this.selectedRowsCount = ff ? ff.length : 0;
    if (ff && ff.length) {
      this.rowIsSelected = true;
    } else {
      this.rowIsSelected = false;
    }
  }

  public getActivities() {
    console.log('this.params', this.params);
    this.apiService.getFormActivities({
      filter: {
        platform: this.platform_id,
        tech_data: {
          application: this.application,
        }
      },
      limit: 50
    }).subscribe((res: any) => {
      this.isActivitiesGrid = true;
      this.rowSelection = {
        enableClickSelection: true,
        mode: 'multiRow',
        hideDisabledCheckboxes: true,
        checkboxes: true,
        isRowSelectable: (params) => this.isActivitiesGrid,
      };

      this.show = false;
      this.setActivitiesColDefs();


      setTimeout(() => {
        this.rowData = res;
        this.show = true;
      }, 10);
    });
  }

  setActivitiesColDefs(): void {
    this.selectedRowsCount = 0;
    this.colDefs = [
      {
        field: 'created_date',
        headerName: 'Created Date',
        width: 150,
        minWidth: 150,
        // filter: 'agDateColumnFilter',
        // maxWidth: 120,
        valueFormatter: (params: any) => {
          return params.value
            ? new Date(params.value.split('.')[0]).toLocaleDateString('en-US')
            : '';
        },
        // filterParams: {
        //   browserDatePicker: true,
        //   filterOptions: [
        //     'equals',
        //     'inRange',
        //   ]
        // },
      },
      {
        field: 'tech_data.application',
        headerName: 'Application',
        width: 110,
        minWidth: 110,
      },
      {
        field: 'tech_data.form_id',
        headerName: 'Form ID',
        width: 150,
        minWidth: 150,
      },
      {
        field: 'automate_data.name',
        headerName: 'Name',
        width: 150,
        minWidth: 150,
      },
      {
        field: 'automate_data.phone',
        headerName: 'Phone',
        width: 150,
        minWidth: 150,
      },
      {
        field: 'automate_data.email',
        headerName: 'Email',
        width: 150,
        minWidth: 150,
      },
    ];
  }
  setDefaultColDefs(): void {
    this.rowSelection = {
      enableClickSelection: true,
      mode: 'singleRow',
      hideDisabledCheckboxes: true,
      checkboxes: false,
      isRowSelectable: (params) => this.isActivitiesGrid,
    };

    this.colDefs = [
      {
        field: 'application',
        headerName: 'Application',
        width: 110,
        minWidth: 110,
      },
      {
        field: 'id',
        headerName: 'Form ID',
        width: 150,
        minWidth: 150,
      },
      {
        field: 'days_since_last_submit',
        headerName: 'Days Since Last Submit',
        width: 220,
        minWidth: 170,
        sort: 'desc',
      },
      {
        field: 'average_days_between_submissions',
        headerName: 'Average Days Between Submissions',
        width: 280,
        minWidth: 280,
      },
      {
        field: 'note',
        headerName: 'Notes',
        width: 200,
        minWidth: 170,
        flex: 1,
        // editable: true,
        editable: (params: any) => {
          // console.log('editable', params);
          if (params.data.id) {
            return true;
          } else {
            return false;
          }
        },
        valueGetter: (params: any) => {
          // console.log('valueGetter', params);
          if (params.data.note) {
            return params.data.note;
          } else if (!params.data.id) {
            return 'Form ID not set';
          } else {
            return '';
          }
        },
        cellRenderer: CommentCellRendererComponent,
        cellEditor: 'agLargeTextCellEditor',
      },
      // {
      //   field: 'exception',
      //   headerName: 'Exception',
      //   pinned: 'right',
      //   sortable: false,
      //   filter: false,
      //   width: 110,
      //   resizable: false,
      //   cellRenderer: ViewCellRenderComponent,
      //   cellRendererParams: {
      //     platform_id: this.params.data.platform_id,
      //     isFormException: true,
      //     row_id: this.params.data._id,
      //   },
      // },
      {
        field: 'exception',
        headerName: 'Actions',
        pinned: 'right',
        sortable: false,
        filter: false,
        width: 210,
        resizable: false,
        cellRenderer: OverviewFormsSearchActivitiesCellRendererComponent,
        cellRendererParams: {
          platform_id: this.platform_id,
          isFormException: true,
          row_id: this.params.data._id,
        },
      },
    ];
  }

  onHidePopover() {
    console.log('-=-');
    this.show = false;
    this.isActivitiesGrid = false;
    this.setDefaultColDefs();
  }

  overviewUpdate() {
    const rowNode = this.storageService.overviewGridApi?.getRowNode(this.params.data._id)!;
    rowNode.setData({ ...rowNode.data, ...{ row_is_blocked: true } });

    this.apiService.generateOverviewAnalytics({
      platform_id: this.platform_id
    }).subscribe((res: any) => {
      const rowNode = this.storageService.overviewGridApi?.getRowNode(this.params.data._id)!;
      // rowNode.setData({ ...rowNode.data, ...{ row_is_blocked: true } });
      rowNode.setData({ ...res, ...{ row_is_blocked: false } });
    });
  }

  test() {
    let fff = this.storageService.overviewGridApi?.getSelectedRows();
    console.log('================================', fff, this.platform_id);
    const rowNode = this.storageService.overviewGridApi?.getRowNode(this.params.data._id)!;
    console.log('rowNode', rowNode);
    rowNode.setData({ ...rowNode.data, ...{ row_is_blocked: true } });
  }

  deleteActivitiesAction() {
    const ff: any = this.gridApi?.getSelectedRows();
    this.selectedRowsCount = ff ? ff.length : 0;
    // console.log('ff', ff);
    this.apiService.deleteFormActivities({
      ids: ff.map((x: any) => x._id),
      platform_id: this.platform_id
    }).subscribe((res: any) => {
      // console.log('=-=', res);
      this.rowIsSelected = false;
      this.getActivities();

      this.overviewUpdate();
      // this.apiService.generateOverviewAnalytics(this.platform_id).subscribe((res: any) => {
      //   const rowNode = this.gridApi?.getRowNode(event.data.id)!;
      //   rowNode.setData({ ...rowNode.data, ...{ row_is_blocked: true } });
      // });
    });
  }

  showUpdateDialog() {
    this.dismissable = false;
    this.updateDialogRef = this.dialogService.open(OverviewFormsUpdateDialogComponent, {
      header: '',
      styleClass: 'overview-forms-update-dialog',
      data: this.params.data,
      width: '400px',
      height: '300px',
      modal: true,
      closable: true
    });

    this.updateDialogRef?.onClose.subscribe((data: any) => {
      console.log('updateDialogRef CLOSE ****', data);
      this.dismissable = true;

      if (data && (data.form_id || data.form_name)) {
        const ff: any = this.gridApi?.getSelectedRows();
        this.selectedRowsCount = ff ? ff.length : 0;
        this.apiService.updateFormActivities({
          ids: ff.map((x: any) => x._id),
          form_id: data.form_id,
          form_name: data.form_name
        }).subscribe((res: any) => {
          this.rowIsSelected = false;
          this.getActivities();
          this.overviewUpdate();
        });
      }
    });
  }

  deleteActivitiesConfirm() {
    this.dismissable = false;
    this.confirmationService.confirm({
      message: 'This action will update both the default table and analytics. Do you want to proceed?',
      header: 'Confirmation',
      closable: false,
      closeOnEscape: false,
      // icon: 'pi pi-exclamation-triangle',
      rejectButtonProps: {
        label: 'Cancel',
        severity: 'secondary',
        outlined: true,
      },
      acceptButtonProps: {
        label: 'OK',
      },
      accept: () => {
        setTimeout(() => {
          this.dismissable = true;
          this.deleteActivitiesAction();
        }, 10);
      },
      reject: () => {
        setTimeout(() => {
          this.dismissable = true;
        }, 10);
      },
    });
  }

  backStep() {
    this.show = false;

    setTimeout(() => {
      this.setDefaultColDefs();

      if (this.selectedFormType === 'forms') {
        this.rowData = this.forms;
      } else if (this.selectedFormType === 'problems') {
        this.rowData = this.problems;
      } else if (this.selectedFormType === 'exceptions') {
        this.rowData = this.exceptions;
      }

      this.isActivitiesGrid = false;
      this.show = true;
    }, 10);
  }

  showPopup(type: string, event: any, popover: any) {
    this.show = false;
    this.selectedFormType = type;

    if (type === 'forms') {
      this.rowData = this.forms;
    } else if (type === 'problems') {
      this.rowData = this.problems;
    } else if (type === 'exceptions') {
      this.rowData = this.exceptions;
    }

    setTimeout(() => {
      this.show = true;
      popover.show(event);
    }, 220);
  }

  public getRowId: GetRowIdFunc = (params: GetRowIdParams) => {
    return String(params.data.id || params.data._id);
  };

  onCellEditingStopped(event: any) {
    const data = {
      platform_id: this.params.data.platform_id,
      field: 'update-form',
      value: {
        id: event.data.id,
        note: event.value,
      },
    };

    const rowNode = this.gridApi?.getRowNode(event.data.id)!;
    rowNode.setData({ ...rowNode.data, ...{ row_is_blocked: true } });

    this.http.post(environment.API_PATH + `/analytics/overview`, data).subscribe({
      next: async (res: any) => {
        rowNode.setData({ ...rowNode.data, ...{ row_is_blocked: false } });
        this.messageService.add({
          severity: 'success',
          summary: 'Overview Update',
          detail: 'Form Data Successfully Updated',
        });
      },
      error: (err) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Overview Update Error!',
          detail: err.message,
        });
      },
    });
  }

  async onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
  }

  refresh(params: any): boolean {
    this.forms = params?.data?.forms.filter((f: any) => f.exception !== true);
    this.total = this.forms.length;
    if (params?.data?.forms?.filter) {
      this.problems = params?.data?.forms?.filter((f: any) => f.problem === true && f.exception !== true);
      this.exceptions = params?.data?.forms?.filter((f: any) => f.exception === true);
    }
    return true;
  }
}
