import { Component, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { StorageService } from '../../../../services/storage.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ICellRendererParams } from 'ag-grid-community';
import { environment } from '../../../../../environments/environment';
import { ConfirmDialog } from 'primeng/confirmdialog';
import { Select } from 'primeng/select';
import { FormsModule } from '@angular/forms';
import { Tooltip } from 'primeng/tooltip';
import { ApiService } from '../../../../services/api.service';

@Component({
  selector: 'app-overview-brand-renderer',
  imports: [ConfirmDialog, Select, FormsModule, Tooltip],
  templateUrl: './overview-brand-renderer.component.html',
  styleUrl: './overview-brand-renderer.component.scss',
  providers: [ConfirmationService],
})
export class OverviewBrandRendererComponent {
  public apiService: ApiService = inject(ApiService);
  private storageService: StorageService = inject(StorageService);
  public messageService: MessageService = inject(MessageService);

  private initialValue: string = '';

  public loading: boolean = false;
  private params: any;
  public cellValue: any = {};
  public showWarning: boolean = false;
  public warningText: string = 'This field is required';
  public disabled: boolean = false;

  values: any = [];

  constructor(private confirmationService: ConfirmationService) {}

  agInit(params: any): void {
    this.values = params.values;
    this.params = params;
    this.cellValue = params.value;
    this.initialValue = params.value;

    this.loading = this.params.data.row_is_blocked;

    if (params.data.client_name === 'Temporary Client' || params.data.client_name === '') {
      this.warningText = 'Before selecting you need to set Client Name';
      this.showWarning = true;
      this.disabled = true;
    } else if (!this.cellValue) {
      this.warningText = 'This field is required';
      this.showWarning = true;
      this.disabled = false;
    }
  }

  onDropdownClick(event: any): void {
    console.log('onDropdownClick');
  }

  updateValue(value: any) {
    const data = {
      platform_id: this.params.data.platform_id,
      field: this.params.colDef.field,
      value,
    };

    const rowNode = this.params.api.getRowNode(this.params.data._id)!;
    rowNode.setData({ ...this.params.data, ...{ [this.params.colDef.field]: value?.label, row_is_blocked: true } });

    this.apiService.overviewUpdateValue(data).subscribe({
      next: (res: any) => {
        this.params.api.applyTransaction({
          update: [res],
        });
        this.messageService.add({
          severity: 'success',
          summary: 'Overview Update',
          detail: 'Data Successfully Updated',
        });

        const rowNode = this.params.api.getRowNode(this.params.data._id)!;
        rowNode.setData({ ...res, ...{ [this.params.colDef.field]: value?.label, row_is_blocked: false } });
      },
      error: (err) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Overview Update Error!',
          detail: err.message,
        });

        const rowNode = this.params.api.getRowNode(this.params.data._id)!;
        rowNode.setData({
          ...this.params.data,
          ...{ [this.params.colDef.field]: this.initialValue, row_is_blocked: false },
        });
      },
    });
  }

  onChangeValue(event: any): void {
    // console.log('onChangeValue', event);
    this.confirmationService.confirm({
      message: 'This action will update both the default table and analytics. Do you want to proceed?',
      header: 'Confirmation',
      closable: false,
      closeOnEscape: false,
      // icon: 'pi pi-exclamation-triangle',
      rejectButtonProps: {
        label: 'Cancel',
        severity: 'secondary',
        outlined: true,
      },
      acceptButtonProps: {
        label: 'Save',
      },
      accept: () => {
        if (this.values.find((v: any) => v.value === event.value)?.data) {
          this.updateValue(this.values.find((v: any) => v.value === event.value)?.data);
        } else {
          this.updateValue({name: "report_brand", value: null});
        }
        // this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted' });
      },
      reject: () => {
        this.cellValue = this.initialValue;
      },
    });
  }

  refresh(params: ICellRendererParams) {
    this.params = params;
    return true;
  }
}
