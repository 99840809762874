import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-overview-total-mqls-renderer',
  imports: [NgClass],
  templateUrl: './overview-total-mqls-renderer.component.html',
  styleUrl: './overview-total-mqls-renderer.component.scss',
})
export class OverviewTotalMqlsRendererComponent {
  protected params: any = {};
  protected values: any = [];
  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.refresh(params);
  }
  refresh(params: any): boolean {
    this.values = params?.values;
    // this.values = {
    //   first: 123123,
    //   last: 222222
    // }
    return true;
  }
}
