  <div class="date-selector-wrapper" [class.date-selector-wrapper--disabled]="showProjectWarnings || disabled">
<!--    <div class="input-disabled-overlay"></div>-->
    <p-datepicker
      [(ngModel)]="selectedDate"
      [dateFormat]="'mm/dd/yy'"
      [placeholder]="'--/--/----'"
      [dataType]="'string'"
      [minDate]="minDate"
      [maxDate]="maxDate"
      [disabled]="disabled"
      [iconDisplay]="'input'"
      [showIcon]="showIcon"
      [autofocus]="false"
      [showOnFocus]="true"
      [readonlyInput]="true"
      [appendTo]="'body'"
      [showButtonBar]="true"
      (onClearClick)="onDateChange(true)"
      (onSelect)="onDateChange()"
      (onClose)="onPanelHide()"
      inputId="icondisplay"
    />

    @if (requiredField) {
      <div [pTooltip]="tooltipText" tooltipPosition="top" class="warning-badge">!</div>
    }
  </div>



