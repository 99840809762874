import { Component } from '@angular/core';
import { IDateAngularComp } from 'ag-grid-angular';
import type { IDateParams } from 'ag-grid-community';
import { FormsModule } from '@angular/forms';
import { DatePickerModule } from 'primeng/datepicker';
import { Calendar } from 'primeng/calendar';
import { Tooltip } from 'primeng/tooltip';

@Component({
  selector: 'app-custom-date-filter',
  imports: [DatePickerModule, FormsModule, Calendar, Tooltip],
  templateUrl: './custom-date-filter.component.html',
  styleUrl: './custom-date-filter.component.scss',
})
export class CustomDateFilterComponent implements IDateAngularComp {
  public date!: Date;
  public params!: IDateParams;
  public picker: any;

  agInit(params: IDateParams): void {
    this.params = params;
  }

  onDateChange() {
    console.log('---', this.date);
    this.params.onDateChanged();
  }

  getDate(): any {
    return this.date;
    // return new Date('2025-03-09T00:00:00.000Z');
  }

  refresh(params: IDateParams) {
    return this.date;
  }

  setDate(date: Date): void {
    console.log('setDate', date);
    this.date = date || null;
    // this.picker.setDate(date);
  }
}
