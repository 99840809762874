<div>
  <h3>Form ID</h3>
  <input type="text" pInputText [(ngModel)]="form_id" />
</div>

<div>
  <h3>Form Name</h3>
  <input type="text" pInputText [(ngModel)]="form_name" />
</div>

<div class="case-value-item__buttons">
  <app-button
    (click)="close()"
    label="Cancel"
    [outlined]="true"
    [style]="{'width': '100%', 'border-color': '#7986FF'}"
  />
  <app-button
    (click)="update()"
    label="Update"/>
</div>
