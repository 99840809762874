import { Component, inject } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { Button } from 'primeng/button';
import { environment } from '../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { StorageService } from '../../../../services/storage.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-crux-renderer',
  imports: [Button],
  templateUrl: './crux-renderer.component.html',
  styleUrl: './crux-renderer.component.scss',
})
export class CruxRendererComponent implements ICellRendererAngularComp {
  private http = inject(HttpClient);
  private storageService: StorageService = inject(StorageService);
  public messageService: MessageService = inject(MessageService);

  protected params: any = {};
  protected crux: any = {};
  protected loading: boolean = false;
  constructor() {}
  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.refresh(params);
    this.loading = this.params.data.row_is_blocked;
  }

  refresh(params: any): boolean {
    this.crux = params?.data?.crux;
    return true;
  }

  update() {
    // this.loading = true;
    const data = {
      platform_id: this.params.data.platform_id,
      field: 'crux-hp',
    };

    const rowNode = this.params.api.getRowNode(this.params.data._id)!;
    rowNode.setData({...this.params.data, ...{row_is_blocked: true}});

    this.http
      .post(environment.API_PATH + '/analytics/overview', data).subscribe({
      next: (res: any) => {
        this.params.api.applyTransaction({
          update: [res],
        });
        // this.loading = false;

        const rowNode = this.params.api.getRowNode(this.params.data._id)!;
        rowNode.setData({...res, ...{row_is_blocked: false}});

        this.messageService.add({
          severity: 'success',
          summary: 'Overview Update',
          detail: 'CRUX Realtime HP Data Successfully Updated',
        });
      },
      error: (err) => {
        this.messageService.add({
          severity: 'error',
          summary: 'CRUX Update Error!',
          detail: err.message,
        });
        // this.loading = false;
        const rowNode = this.params.api.getRowNode(this.params.data._id)!;
        rowNode.setData({...this.params.data, ...{row_is_blocked: false}});
      },
      complete: () => console.info('complete')
    });
  }
}
