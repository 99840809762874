import { Component, inject } from '@angular/core';
import { ConfirmationService, MessageService, PrimeTemplate } from 'primeng/api';
import { Select } from 'primeng/select';
import { ICellRendererParams } from 'ag-grid-community';
import { HttpClient } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { StorageService } from '../../../../services/storage.service';
import { environment } from '../../../../../environments/environment';
import { ConfirmDialog } from 'primeng/confirmdialog';
import { Tooltip } from 'primeng/tooltip';
import { ApiService } from '../../../../services/api.service';

@Component({
  selector: 'app-overview-reporting-service-renderer',
  imports: [Select, FormsModule, ConfirmDialog, Tooltip],
  templateUrl: './overview-reporting-service-renderer.component.html',
  styleUrl: './overview-reporting-service-renderer.component.scss',
  providers: [ConfirmationService],
})
export class OverviewReportingServiceRendererComponent {
  public apiService: ApiService = inject(ApiService);
  private storageService: StorageService = inject(StorageService);
  public messageService: MessageService = inject(MessageService);
  public disabled: boolean = false;
  public seo_project_is_active: boolean = false;

  private initialValue: string = '';
  public requiredField: boolean = false;
  public warningText: string = 'This field is required';

  public loading: boolean = false;
  private params: any;
  public cellValue: any = {};

  values: any = [];

  constructor(private confirmationService: ConfirmationService) {}

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.cellValue = params.value;
    this.initialValue = params.value;

    this.seo_project_is_active = this.params.data.projects?.findIndex((p: any) => p.type === 'PPC' && p.status === 'active') !== -1;
    this.loading = this.params.data.row_is_blocked;
    this.updateSelectValues();

    if (!this.cellValue && this.seo_project_is_active) {
      this.requiredField = true;
    }

    if (this.params.data.client_name === 'Temporary Client') {
      this.disabled = true;
      this.requiredField = true;
      this.warningText = 'Before selecting you need to set Client Name';
    }
  }

  private updateSelectValues() {
    if (this.cellValue === '' || this.cellValue === null || this.cellValue === undefined) {
      this.values = [
        { value: 'Agency Analytics', label: 'Agency Analytics' },
        { value: 'Client Portal', label: 'Client Portal' },
        { value: 'Raven', label: 'Raven' },
      ];
    } else {
      this.values = [
        { value: '', label: 'Not Set' },
        { value: 'Agency Analytics', label: 'Agency Analytics' },
        { value: 'Client Portal', label: 'Client Portal' },
        { value: 'Raven', label: 'Raven' },
      ];
    }
  }

  onDropdownClick(event: any): void {
    console.log('onDropdownClick');
  }

  updateValue(value: any) {
    const data = {
      platform_id: this.params.data.platform_id,
      field: this.params.colDef.field,
      value: value === '' ? null : value,
    };

    const rowNode = this.params.api.getRowNode(this.params.data._id)!;
    rowNode.setData({ ...this.params.data, ...{ reporting_service: value, row_is_blocked: true } });

    this.apiService.overviewUpdateValue(data).subscribe({
      next: (res: any) => {
        this.params.api.applyTransaction({
          update: [res],
        });
        this.updateSelectValues();
        this.messageService.add({
          severity: 'success',
          summary: 'Overview Update',
          detail: 'Data Successfully Updated',
        });

        const rowNode = this.params.api.getRowNode(this.params.data._id)!;
        rowNode.setData({ ...res, ...{ reporting_service: value, row_is_blocked: false } });
      },
      error: (err) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Overview Update Error!',
          detail: err.message,
        });

        const rowNode = this.params.api.getRowNode(this.params.data._id)!;
        rowNode.setData({ ...this.params.data, ...{ reporting_service: this.initialValue, row_is_blocked: false } });
      },
    });
  }

  onChangeValue(event: any): void {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: 'This action will update both the default table and analytics. Do you want to proceed?',
      header: 'Confirmation',
      closable: false,
      closeOnEscape: false,
      // icon: 'pi pi-exclamation-triangle',
      rejectButtonProps: {
        label: 'Cancel',
        severity: 'secondary',
        outlined: true,
      },
      acceptButtonProps: {
        label: 'Save',
      },
      accept: () => {
        this.updateValue(event.value);
        // this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted' });
      },
      reject: () => {
        this.cellValue = this.initialValue;
        this.updateSelectValues();
      },
    });
  }

  refresh(params: ICellRendererParams) {
    this.params = params;
    return true;
  }
}
