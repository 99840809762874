@let click_synced = google_ads_conversion_action?.cp_auto_upload_clicks?.statistic?.synced_contacts?.toLocaleString('en', { maximumFractionDigits: 2 }) || 0;
@let click_synced_exist = google_ads_conversion_action?.cp_auto_upload_clicks?.statistic?.synced_contacts || 0;

@let click_errors = google_ads_conversion_action?.cp_auto_upload_clicks?.statistic?.error_contacts?.toLocaleString('en', { maximumFractionDigits: 2 }) || 0;

@let click_deal_synced = google_ads_conversion_action?.cp_deal_auto_upload_clicks?.statistic?.synced_contacts?.toLocaleString('en', { maximumFractionDigits: 2 }) || 0;
@let click_deal_synced_exist = google_ads_conversion_action?.cp_deal_auto_upload_clicks?.statistic?.synced_contacts || 0;

@let click_deal_errors = google_ads_conversion_action?.cp_deal_auto_upload_clicks?.statistic?.error_contacts?.toLocaleString('en', { maximumFractionDigits: 2 }) || 0;
@let click_errors_list = google_ads_conversion_action?.cp_auto_upload_clicks?.statistic?.error_details || null;
@let click_deal_errors_list = google_ads_conversion_action?.cp_deal_auto_upload_clicks?.statistic?.error_details || null;
@let click_global_error = google_ads_conversion_action?.cp_auto_upload_clicks?.error;
@let click_deal_global_error = google_ads_conversion_action?.cp_deal_auto_upload_clicks?.error;

@let click_check_date = google_ads_conversion_action?.cp_auto_upload_clicks?.checked_date | date: 'MM/dd/yyyy' || '';
@let click_deal_check_date = google_ads_conversion_action?.cp_deal_auto_upload_clicks?.checked_date | date: 'MM/dd/yyyy' || '';

@let calls_synced = google_ads_conversion_action?.cp_auto_upload_calls?.statistic?.synced_contacts?.toLocaleString('en', { maximumFractionDigits: 2 }) || 0;
@let calls_synced_exist = google_ads_conversion_action?.cp_auto_upload_calls?.statistic?.synced_contacts || 0;

@let calls_errors = google_ads_conversion_action?.cp_auto_upload_calls?.statistic?.error_contacts?.toLocaleString('en', { maximumFractionDigits: 2 }) || 0;

@let calls_deal_synced = google_ads_conversion_action?.cp_deal_auto_upload_calls?.statistic?.synced_contacts?.toLocaleString('en', { maximumFractionDigits: 2 }) || 0;
@let calls_deal_synced_exist = google_ads_conversion_action?.cp_deal_auto_upload_calls?.statistic?.synced_contacts || 0;

@let calls_deal_errors = google_ads_conversion_action?.cp_deal_auto_upload_calls?.statistic?.error_contacts?.toLocaleString('en', { maximumFractionDigits: 2 }) || 0;
@let calls_errors_list = google_ads_conversion_action?.cp_auto_upload_calls?.statistic?.error_details || null;
@let calls_deal_errors_list = google_ads_conversion_action?.cp_deal_auto_upload_calls?.statistic?.error_details || null;
@let calls_global_error = google_ads_conversion_action?.cp_auto_upload_calls?.error;
@let calls_deal_global_error = google_ads_conversion_action?.cp_deal_auto_upload_calls?.error;

@let calls_check_date = google_ads_conversion_action?.cp_auto_upload_calls?.checked_date | date: 'MM/dd/yyyy' || '';
@let calls_deal_check_date = google_ads_conversion_action?.cp_deal_auto_upload_calls?.checked_date | date: 'MM/dd/yyyy' || '';

@if (!ppc_project_is_active) {
  <div class="disabled-overlay"></div>
}

@if (Object.keys(google_ads_conversion_action).length >= 4 && google_ads_conversion_action?.cp_auto_upload_clicks?.sync !== undefined) {
  <div class="item">
    <div class="item-top-area">
      <div class="label">GCLID
        @if (ppc_project_is_active && click_global_error) {
          <div class="errors-number">
            <div [pTooltip]="click_global_error" class="warning-badge">!</div>
          </div>
        }
      </div>
      <p-toggleswitch [(ngModel)]="google_ads_conversion_action.cp_auto_upload_clicks.sync" (onChange)="onChangeValue('cp_auto_upload_clicks')" />
    </div>
    <div class="item-bottom-area">
      <div class="item-bottom-area__num-wrap">
        <span class="success-number">{{ click_synced_exist ? click_synced : '-' }}</span>
        @if (click_errors_list && click_errors_list.length) {
          <span class="number-divider">/</span>
          <span (click)="showPopup('exceptions', $event, popover)" class="errors-number">
            {{ click_errors }}</span>
          <p-popover [styleClass]="'overview-google-ads-cnv-act-overlay'" #popover>
            <ul>
              @for (data of click_errors_list; track data.index) {
                <li>
                  <div><b>Field:</b> {{data.error_field}}</div>
                  <div><b>Message:</b> {{data.error_message}}</div>
                  @if (click_errors_list.length) {
                    <p-button class="show-contact-ids-btn" [label]="'Contact IDs ' + '(' + data.contact_ids.length + ')'" (onClick)="click_errors_list[data.index].show = !click_errors_list[data.index]?.show"/>
                  }
                  @if (click_errors_list[data.index]?.show) {
                    <div class="contact-ids-list">
                      <p-button (onClick)="copyToClipboard(data.contact_ids)" class="copy-contact-ids-btn" icon="pi pi-copy" [rounded]="true" severity="info" [outlined]="true" />
                      {{data.contact_ids.join(', ')}}
                    </div>
                  }
                </li>
              }
            </ul>
          </p-popover>
        }
      </div>

      <span [tooltipOptions]="tooltipOptions" [pTooltip]="'Last sync: ' + String(click_check_date)" class="pi pi-calendar"></span>
    </div>
  </div>

  <!-- ************************* -->

  <div class="item">
    <div class="item-top-area">
      <div class="label">Deal GCLID
        @if (ppc_project_is_active && click_deal_global_error) {
          <div class="errors-number">
            <div [pTooltip]="click_deal_global_error" class="warning-badge">!</div>
          </div>
        }
      </div>
      <p-toggleswitch [(ngModel)]="google_ads_conversion_action.cp_deal_auto_upload_clicks.sync" (onChange)="onChangeValue('cp_deal_auto_upload_clicks')" />
    </div>
    <div class="item-bottom-area">
      <div class="item-bottom-area__num-wrap">
        <span class="success-number">{{ click_deal_synced_exist ? click_deal_synced : '-' }}</span>
        @if (click_deal_errors_list && click_deal_errors_list.length) {
          <span class="number-divider">/</span>
          <span (click)="showPopup('exceptions', $event, popover)" class="errors-number">
            {{ click_deal_errors }}</span>
          <p-popover [styleClass]="'overview-google-ads-cnv-act-overlay'" #popover>
            <ul>
              @for (data of click_deal_errors_list; track data.index) {
                <li>
                  <div><b>Field:</b> {{data.error_field}}</div>
                  <div><b>Message:</b> {{data.error_message}}</div>
                  @if (click_deal_errors_list.length) {
                    <p-button class="show-contact-ids-btn" [label]="'Contact IDs ' + '(' + data.contact_ids.length + ')'" (onClick)="click_deal_errors_list[data.index].show = !click_deal_errors_list[data.index]?.show"/>
                  }
                  @if (click_deal_errors_list[data.index]?.show) {
                    <div class="contact-ids-list">
                      <p-button (onClick)="copyToClipboard(data.contact_ids)" class="copy-contact-ids-btn" icon="pi pi-copy" [rounded]="true" severity="info" [outlined]="true" />
                      {{data.contact_ids.join(', ')}}
                    </div>
                  }
                </li>
              }
            </ul>
          </p-popover>
        }
      </div>

      <span [tooltipOptions]="tooltipOptions" [pTooltip]="'Last sync: ' + String(click_deal_check_date)" class="pi pi-calendar"></span>
    </div>
  </div>

  <!-- ************************* -->

  <div class="item">
    <div class="item-top-area">
      <div class="label">Calls
        @if (ppc_project_is_active && calls_global_error) {
          <div class="errors-number">
            <div [pTooltip]="calls_global_error" class="warning-badge">!</div>
          </div>
        }
      </div>
      <p-toggleswitch [(ngModel)]="google_ads_conversion_action.cp_auto_upload_calls.sync" (onChange)="onChangeValue('cp_auto_upload_calls')" />
    </div>
    <div class="item-bottom-area">
      <div class="item-bottom-area__num-wrap">
        <span class="success-number">{{ calls_synced_exist ? calls_synced : '-' }}</span>
        @if (calls_errors_list && calls_errors_list.length) {
          <span class="number-divider">/</span>
          <span (click)="showPopup('exceptions', $event, popover)" class="errors-number">
            {{ calls_errors }}</span>
          <p-popover [styleClass]="'overview-google-ads-cnv-act-overlay'" #popover>
            <ul>
              @for (data of calls_errors_list; track data.index) {
                <li>
                  <div><b>Field:</b> {{data.error_field}}</div>
                  <div><b>Message:</b> {{data.error_message}}</div>
                  @if (calls_errors_list.length) {
                    <p-button class="show-contact-ids-btn" [label]="'Contact IDs ' + '(' + data.contact_ids.length + ')'" (onClick)="calls_errors_list[data.index].show = !calls_errors_list[data.index]?.show"/>
                  }
                  @if (calls_errors_list[data.index]?.show) {
                    <div class="contact-ids-list">
                      <p-button (onClick)="copyToClipboard(data.contact_ids)" class="copy-contact-ids-btn" icon="pi pi-copy" [rounded]="true" severity="info" [outlined]="true" />
                      {{data.contact_ids.join(', ')}}
                    </div>
                  }
                </li>
              }
            </ul>
          </p-popover>
        }
      </div>

      <span [tooltipOptions]="tooltipOptions" [pTooltip]="'Last sync: ' + String(calls_check_date)" class="pi pi-calendar"></span>
    </div>
  </div>

  <!-- ************************* -->

  <div class="item">
    <div class="item-top-area">
      <div class="label">Deal Calls
        @if (ppc_project_is_active && calls_deal_global_error) {
          <div class="errors-number">
            <div [pTooltip]="calls_deal_global_error" class="warning-badge">!</div>
          </div>
        }
      </div>
      <p-toggleswitch [(ngModel)]="google_ads_conversion_action.cp_deal_auto_upload_calls.sync" (onChange)="onChangeValue('cp_deal_auto_upload_calls')" />
    </div>
    <div class="item-bottom-area">
      <div class="item-bottom-area__num-wrap">
        <span class="success-number">{{ calls_deal_synced_exist ? calls_deal_synced : '-' }}</span>
        @if (calls_deal_errors_list && calls_deal_errors_list.length) {
          <span class="number-divider">/</span>
          <span (click)="showPopup('exceptions', $event, popover)" class="errors-number">
            {{ calls_deal_errors }}</span>
          <p-popover [styleClass]="'overview-google-ads-cnv-act-overlay'" #popover>
            <ul>
              @for (data of calls_deal_errors_list; track data.index) {
                <li>
                  <div><b>Field:</b> {{data.error_field}}</div>
                  <div><b>Message:</b> {{data.error_message}}</div>
                  @if (calls_deal_errors_list.length) {
                    <p-button class="show-contact-ids-btn" [label]="'Contact IDs ' + '(' + data.contact_ids.length + ')'" (onClick)="calls_deal_errors_list[data.index].show = !calls_deal_errors_list[data.index]?.show"/>
                  }
                  @if (calls_deal_errors_list[data.index]?.show) {
                    <div class="contact-ids-list">
                      <p-button (onClick)="copyToClipboard(data.contact_ids)" class="copy-contact-ids-btn" icon="pi pi-copy" [rounded]="true" severity="info" [outlined]="true" />
                      {{data.contact_ids.join(', ')}}
                    </div>
                  }
                </li>
              }
            </ul>
          </p-popover>
        }
      </div>

      <span [tooltipOptions]="tooltipOptions" [pTooltip]="'Last sync: ' + String(calls_deal_check_date)" class="pi pi-calendar"></span>
    </div>

    <p-confirmdialog />
  </div>
}
