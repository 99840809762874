@if (total) {
  <div class="first-value" pTooltip="Active forms" tooltipPosition="top" (click)="showPopup('forms', $event, popover)">{{total}}</div>
} @else {
  <div class="first-value" pTooltip="Active forms" tooltipPosition="top">{{total}}</div>
}
@if(problems && problems.length > 0) {
  &nbsp;/&nbsp;
<!--  <div class="last-value" (click)="pr.toggle($event)">{{problems.length}}</div>-->
  <div class="last-value" pTooltip="Problem forms" tooltipPosition="top" (click)="showPopup('problems' ,$event, popover)">{{problems.length}}</div>
}
@if(exceptions && exceptions.length > 0) {
  &nbsp;/&nbsp;
<!--  <div class="last-value last-value__lvl2" (click)="ex.toggle($event)">{{exceptions.length}}</div>-->
  <div class="last-value last-value__lvl2" pTooltip="Archived forms" tooltipPosition="top" (click)="showPopup('exceptions', $event, popover)">{{exceptions.length}}</div>
}

<p-popover (onHide)="onHidePopover()" [dismissable]="dismissable" [styleClass]="'overview-forms-overlay'" #popover>
  @if (show) {
    <ag-grid-angular
      class="ag-forms"
      [rowData]="rowData"
      [columnDefs]="colDefs"
      [rowClassRules]="rowClassRules"
      [rowSelection]="rowSelection"
      (selectionChanged)="selectionChanged()"
      (cellValueChanged)="onCellEditingStopped($event)"
      [singleClickEdit]="true"
      [getRowId]="getRowId"
      [defaultColDef]="defaultColDef"
      (gridReady)="onGridReady($event)"
    />


    <p-confirmdialog />
    @if (isActivitiesGrid) {
      <div class="overview-forms-bottom-actions">
          <span class="rows-counter">
            @if (selectedRowsCount) {
              {{selectedRowsCount}} activities selected
            }
          </span>
        <div>
          <button (click)="test()">test</button>
          <p-button (onClick)="backStep()" label="Back" icon="pi pi-chevron-left" />
          <p-button [disabled]="!rowIsSelected" (onClick)="deleteActivitiesConfirm()" label="Delete Activities" icon="pi pi-trash" />
          <p-button [disabled]="!rowIsSelected" (onClick)="showUpdateDialog()" label="Update Form Tech Data" icon="pi pi-file-edit" />
        </div>
      </div>
    }
  }
</p-popover>

