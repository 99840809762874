import { Component, inject } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { DatePipe } from '@angular/common';
import { Tooltip } from 'primeng/tooltip';
import { Popover } from 'primeng/popover';
import { FormsModule } from '@angular/forms';
import { DatePicker } from 'primeng/datepicker';
import { Button } from 'primeng/button';
import { ButtonComponent } from '../../button/button.component';
import { ConfirmationService, MessageService } from 'primeng/api';
import { environment } from '../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../../../../services/api.service';

@Component({
  selector: 'app-overview-projects-renderer',
  imports: [DatePipe, Tooltip, Popover, FormsModule, DatePicker, Button, ButtonComponent],
  templateUrl: './overview-projects-renderer.component.html',
  styleUrl: './overview-projects-renderer.component.scss',
})
export class OverviewProjectsRendererComponent {
  public messageService: MessageService = inject(MessageService);
  public apiService: ApiService = inject(ApiService);
  protected params: any = {};
  protected projects: any = [];
  public disabled: boolean = false;

  popoverIsActive: boolean = false;

  selectedProject: any = {
    _id: null,
    type: null,
    status: null,
    start_date: null,
    end_date: null,
    first_invoice_date: null,
    last_invoice_date: null,
    warranty_expiration_date: null,
    reconciliation: null
  };

  // fix

  private start_date: any;
  private end_date: any;

  constructor(private confirmationService: ConfirmationService) {}
  agInit(params: ICellRendererParams): void {
    this.params = params;


    if (this.params.data.client_name === 'Temporary Client') {
      this.disabled = true;
      // this.tooltipText = 'Before selecting you need to set Client Name';
    }

    this.refresh(params);
  }

  onClickFirstDateInvoice() {
    const [s_year, s_month, s_day] = this.selectedProject.first_invoice_date?.split('T')[0]?.split('-');
    this.selectedProject.start_date = `${s_month}/${s_day}/${s_year}`;
    this.onCheckType('start_date');
  }
  onClickLastDateInvoice() {
    const [s_year, s_month, s_day] = this.selectedProject.last_invoice_date?.split('T')[0]?.split('-');
    this.selectedProject.end_date = `${s_month}/${s_day}/${s_year}`;
    this.onCheckType('end_date');
  }

  showPopover(project: any, popover: any, event: any): void {
    if (this.disabled === false) {
      let sdate: string = '';
      let edate: string = '';
      if (project.start_date) {
        const [s_year, s_month, s_day] = project.start_date?.split('T')[0]?.split('-');
        sdate = `${s_month}/${s_day}/${s_year}`;
      }
      if (project.end_date) {
        const [e_year, e_month, e_day] = project.end_date?.split('T')[0]?.split('-');
        edate = `${e_month}/${e_day}/${e_year}`;
      }

      this.selectedProject = {
        _id: project._id,
        type: project.type,
        status: project.status,
        start_date: sdate,
        end_date: edate,
        first_invoice_date: project.first_invoice_date,
        last_invoice_date: project.last_invoice_date
      };

      if (this.params.data.real_launch_date) {
        const real_launch_date = new Date(this.params.data.real_launch_date);
        real_launch_date.setMonth(real_launch_date.getMonth() + 1);
        this.selectedProject.warranty_expiration_date = real_launch_date;
      }

      if (project.type === 'DEV') {
        this.selectedProject.reconciliation = this.params.data.reconciliation;
      }

      popover.toggle(event);
    }
  }

  onCheckType(field: any, clearClick: boolean = false) {
    if (clearClick && field === 'start_date') {
      this.start_date = undefined;
    } else if (clearClick && field === 'end_date') {
      this.end_date = undefined;
    }

    const temp_date_range: any = { start_date: null, end_date: null };
    if (this.selectedProject.start_date) {
      const [s_month, s_day, s_year] = this.selectedProject.start_date.split('/');
      temp_date_range.start_date = `${s_year}-${s_month.padStart(2, '0')}-${s_day.padStart(2, '0')}T00:00:00`;
    }

    if (this.selectedProject.end_date) {
      const [e_month, e_day, e_year] = this.selectedProject.end_date.split('/');
      temp_date_range.end_date = `${e_year}-${e_month.padStart(2, '0')}-${e_day.padStart(2, '0')}T00:00:00`;
    }

    this.start_date = temp_date_range.start_date;
    this.end_date = temp_date_range.end_date;
  }

  save() {
    this.confirmationService.confirm({
      message: 'This action will update both the default table and analytics. Do you want to proceed?',
      header: 'Confirmation',
      closable: false,
      closeOnEscape: false,
      rejectButtonProps: {
        label: 'Cancel',
        severity: 'secondary',
        outlined: true,
      },
      acceptButtonProps: {
        label: 'Save',
      },
      accept: () => {
        this.updateValue();
      },
      reject: () => {},
    });
  }

  updateValue() {
    const data = {
      platform_id: this.params.data.platform_id,
      field: 'project',
      value: {
        project_id: this.selectedProject._id,
        start_date: this.start_date,
        end_date: this.end_date,
      },
    };

    const rowNode = this.params.api.getRowNode(this.params.data._id)!;
    rowNode.setData({ ...this.params.data, ...{ row_is_blocked: true } });

    this.apiService.overviewUpdateValue(data).subscribe({
      next: (res: any) => {
        this.params.api.applyTransaction({
          update: [res],
        });

        this.messageService.add({
          severity: 'success',
          summary: 'Overview Update',
          detail: 'Data Successfully Updated',
        });

        const rowNode = this.params.api.getRowNode(this.params.data._id)!;
        rowNode.setData({ ...res, ...{ row_is_blocked: false } });
      },
      error: (err) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Overview Update Error!',
          detail: err.message,
        });
      },
    });
  }
  refresh(params: any): boolean {
    this.projects = params?.data?.projects;

    this.projects = this.projects.sort((a: any, b: any) => {
      const typeOrder: any = { SEO: 1, 'SEO-INT': 1, PPC: 2, 'PPC-INT': 2, DEV: 3, 'DEV-INT': 3, 'OTH-INT': 4 };
      if (a.status === 'active' && b.status !== 'active') return -1;
      if (a.status !== 'active' && b.status === 'active') return 1;
      return typeOrder[a.type] - typeOrder[b.type];
    });

    return true;
  }
}
