import { Component, inject } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { Button } from 'primeng/button';
import { StorageService } from '../../../../services/storage.service';
import { environment } from '../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-overview-forms-search-activities-cell-renderer',
  imports: [Button],
  templateUrl: './overview-forms-search-activities-cell-renderer.component.html',
  styleUrl: './overview-forms-search-activities-cell-renderer.component.scss',
})
export class OverviewFormsSearchActivitiesCellRendererComponent {
  private storageService: StorageService = inject(StorageService);
  public messageService: MessageService = inject(MessageService);
  private http = inject(HttpClient);

  public isFormException: boolean = false;
  public row_id: string = '';

  params: any = {};
  public form_id: string = '';
  public platform_id: string = '';
  public application: string = '';

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.form_id = this.params.data.id;

    this.platform_id = this.params.platform_id;
    this.application = this.params.data.application;
    this.row_id = this.params.row_id;
    this.isFormException = this.params.isFormException;
  }

  searchActivities() {
    this.storageService.$searchActivitiesActionCLick.next({
      platform_id: this.platform_id,
      application: this.application,
    });
  }

  onClickException() {
    console.log('this.params', this.params);
    const data = {
      platform_id: this.platform_id,
      field: 'update-form',
      value: {
        id: this.params.data.id,
        exception: !this.params.data.exception
      },
    };

    this.storageService.$overviewShowRowLoader.next(this.row_id);

    this.http.post(environment.API_PATH + `/analytics/overview`, data).subscribe({
      next: async (res: any) => {
        this.storageService.$overviewGridUpdate.next(res)
        this.messageService.add({
          severity: 'success',
          summary: 'Overview Update',
          detail: 'Form Exceptions Data Successfully Updated',
        });
      },
      error: (err) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Overview Update Error!',
          detail: err.message,
        });
      }
    });
  }

  refresh(params: any): boolean {
    this.params = params;

    return true;
  }
}
