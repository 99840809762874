<div class="select-wrap">
  <p-select
    [options]="values"
    [appendTo]="'body'"
    [filter]="true"
    [showClear]="true"
    [disabled]="disabled"
    (onClick)="onDropdownClick($event)"
    (onChange)="onChangeValue($event)"
    [(ngModel)]="cellValue"
    scrollHeight="300px"
    placeholder="Select">
  </p-select>

  @if (requiredField) {
    <div [pTooltip]="warningText" tooltipPosition="top" class="warning-badge">!</div>
  }
</div>
