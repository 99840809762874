import { Component, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { StorageService } from '../../../../services/storage.service';
import { ICellRendererParams } from 'ag-grid-community';
import { Select } from 'primeng/select';
import { FormsModule } from '@angular/forms';
import { ConfirmationService, MessageService, PrimeTemplate } from 'primeng/api';
import { NgStyle } from '@angular/common';
import { environment } from '../../../../../environments/environment';
import { ConfirmDialog } from 'primeng/confirmdialog';
import { Tooltip } from 'primeng/tooltip';
import { ApiService } from '../../../../services/api.service';

@Component({
  selector: 'app-overview-risk-renderer',
  imports: [Select, FormsModule, PrimeTemplate, NgStyle, ConfirmDialog, Tooltip],
  templateUrl: './overview-risk-renderer.component.html',
  styleUrl: './overview-risk-renderer.component.scss',
  providers: [ConfirmationService],
})
export class OverviewRiskRendererComponent {
  private params: any;
  public field: string = '';
  public isLegal: boolean = false;
  public requiredField: boolean = false;
  public showSearch: boolean = true;
  public disabled: boolean = false;

  public apiService: ApiService = inject(ApiService);
  private storageService: StorageService = inject(StorageService);
  public messageService: MessageService = inject(MessageService);

  public selectedLabel: string = '';
  public selectedFill: string = '';
  public placeholder: string = 'Select';
  public warningText: string = 'This field is required';

  public values: any[] = [];
  public cellValue: any = {};
  public initialValue: any = {};

  constructor(private confirmationService: ConfirmationService) {}

  agInit(params: any): void {
    this.values = params.values;

    this.field = params.colDef.field;
    this.isLegal = params.data.industry === 'legal';

    if (params.placeholder) {
      this.placeholder = params.placeholder;
    }

    // if (this.field === 'risk_factor' && this.values && (params.data.risk_reason === null || params.data.risk_reason === '')) {
    //   this.riskWarningBadgeShow = true;
    // }

    if (['risk_factor', 'status', 'icp'].includes(this.field)) {
      this.showSearch = false;
    }

    this.initialValue = params.value;
    this.params = params;
    this.cellValue = params.value;

    if (this.field === 'practice_area' && this.isLegal && !this.cellValue) {
      this.requiredField = true;
    } else if (!this.cellValue && this.field !== 'practice_area' && this.field !== 'risk_reason') {
      this.requiredField = true;
    }

    if (params.data.risk_factor || params.data.risk_reason) {
      // if (this.field === 'risk_reason' && (!this.cellValue) && (params.data.risk_factor !== null || params.data.risk_factor !== '')) {
      //   this.requiredField = true;
      // }

      if (this.field === 'risk_reason' && params.data.risk_factor !== null && params.data.risk_factor !== 'Low' && !this.cellValue) {
        this.requiredField = true;
      }

      if (this.field === 'risk_factor' && (!this.cellValue) && (params.data.risk_reason !== null || params.data.risk_reason !== '')) {
        this.requiredField = true;
      }
    }

    if (this.field === 'icp' || this.field === 'crm') {
      this.requiredField = false;
    }

    if (this.params.data.client_name === 'Temporary Client' && this.field !== 'practice_area') {
      this.disabled = true;
      this.requiredField = true;
      this.warningText = 'Before selecting you need to set Client Name';
    }

    this.selectedLabel = this.values.find((l: any) => l.value === this.cellValue)?.label;
    this.selectedFill = this.values.find((l: any) => l.value === this.cellValue)?.fill;
  }

  onDropdownClick(event: any): void {}

  updateValue(value: any) {
    const data = {
      platform_id: this.params.data.platform_id,
      field: this.params.colDef.field,
      value: value === '' ? null : value,
    };

    if (this.params.colDef.field === 'client_name') {
      data.value = {
        _id: this.storageService.clients.find((c: any) => c.label === value)._id,
        name: value,
      }
    }

    const rowNode = this.params.api.getRowNode(this.params.data._id)!;
    rowNode.setData({ ...this.params.data, ...{ [this.params.colDef.field]: value, row_is_blocked: true } });

    this.selectedLabel = this.values.find((l: any) => l.value === this.cellValue)?.label;
    this.selectedFill = this.values.find((l: any) => l.value === this.cellValue)?.fill;

    this.apiService.overviewUpdateValue(data).subscribe({
      next: (res: any) => {
        this.params.api.applyTransaction({
          update: [res],
        });
        this.messageService.add({
          severity: 'success',
          summary: 'Overview Update',
          detail: 'Data Successfully Updated',
        });

        const rowNode = this.params.api.getRowNode(this.params.data._id)!;
        rowNode.setData({ ...res, ...{ [this.params.colDef.field]: value, row_is_blocked: false } });

        this.selectedLabel = this.values.find((l: any) => l.value === this.cellValue)?.label;
        this.selectedFill = this.values.find((l: any) => l.value === this.cellValue)?.fill;
      },
      error: (err) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Overview Update Error!',
          detail: err.message,
        });

        const rowNode = this.params.api.getRowNode(this.params.data._id)!;
        rowNode.setData({
          ...this.params.data,
          ...{ [this.params.colDef.field]: this.initialValue, row_is_blocked: false },
        });
      },
    });
  }

  onChangeValue(event: any): void {
    console.log('this.cellValue', this.cellValue);
    console.log('onChangeValue', event.value);
    // this.cellValue = event.value;
    this.selectedLabel = this.values.find((l: any) => l.value === this.cellValue)?.label;
    this.selectedFill = this.values.find((l: any) => l.value === this.cellValue)?.fill;

    this.confirmationService.confirm({
      message: 'This action will update both the default table and analytics. Do you want to proceed?',
      header: 'Confirmation',
      closable: false,
      closeOnEscape: false,
      // icon: 'pi pi-exclamation-triangle',
      rejectButtonProps: {
        label: 'Cancel',
        severity: 'secondary',
        outlined: true,
      },
      acceptButtonProps: {
        label: 'Save',
      },
      accept: () => {
        this.updateValue(event.value);
        // this.updateValue(this.values.find((l: any) => l.value === event.value));
      },
      reject: () => {
        this.cellValue = this.initialValue;

        this.selectedLabel = this.values.find((l: any) => l.value === this.cellValue)?.label;
        this.selectedFill = this.values.find((l: any) => l.value === this.cellValue)?.fill;
      },
    });
  }

  refresh(params: ICellRendererParams) {
    this.params = params;
    return true;
  }
}
