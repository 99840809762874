@if (values) {
  <div class="flex align-items-center gap-1">
    <span class="first-value">{{ values.first ? values.first.toLocaleString('en', { maximumFractionDigits: 2 }) : '--' }}</span>
    <span class="last-value">/ {{ values.last ? values.last.toLocaleString('en', { maximumFractionDigits: 2 }) : '--' }}</span>
    <span
      class="val"
      [ngClass]="[values.difference > 0 ? 'difference-up' : 'difference-down']">
      @if (values.difference > 0) {
        (+{{ values.difference }}%)
      } @else if (values.difference === 0) {

      } @else {
        ({{ values.difference }}%)
      }
  </span>
  </div>
}
