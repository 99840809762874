import { Component, inject } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { ToggleSwitch } from 'primeng/toggleswitch';
import { ApiService } from '../../../../services/api.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ConfirmDialog } from 'primeng/confirmdialog';
import { DatePipe } from '@angular/common';
import { Tooltip } from 'primeng/tooltip';
import { FormsModule } from '@angular/forms';
import { Button } from 'primeng/button';
import { Popover } from 'primeng/popover';

@Component({
  selector: 'app-overview-google-ads-conversion-action-v2-renderer',
  imports: [ToggleSwitch, ConfirmDialog, DatePipe, Tooltip, FormsModule, Button, Popover],
  templateUrl: './overview-google-ads-conversion-action-v2-renderer.component.html',
  styleUrl: './overview-google-ads-conversion-action-v2-renderer.component.scss',
  providers: [ConfirmationService],
})
export class OverviewGoogleAdsConversionActionV2RendererComponent {
  private apiService: ApiService = inject(ApiService);
  private messageService: MessageService = inject(MessageService);
  private params: any;
  public value: any;

  public ppc_project_is_active: boolean = false;

  public google_ads_conversion_action: any = {};
  public initial_google_ads_conversion_action: any = {};

  public tooltipOptions: any = {
    id: 'google-ads-conversion-action-tooltip',
  };

  public gclid_errors: any = [];
  public calls_errors: any = [];

  constructor(private confirmationService: ConfirmationService) {}

  agInit(params: any): void {
    this.params = params;
    this.value = params.value;
    // this.value = params.data.google_ads_conversion_action;

    this.ppc_project_is_active = this.params.data.projects?.findIndex((p: any) => p.type === 'PPC' && p.status === 'active') !== -1;

    if (this.value && this.value.cp_auto_upload_clicks) {
      for (const key of Object.keys(this.value)) {
        this.google_ads_conversion_action[key] = this.value[key];

        if (key !== 'sync') {
          this.google_ads_conversion_action[key].sync = this.value[key].sync || false;
        }

        if (this.google_ads_conversion_action[key]?.statistic?.error_details) {
          let index = 0;
          let temp = [];
          console.log('this.value?.statistic?.error_details', this.value?.statistic?.error_details);

          if (this.value[key]?.statistic?.error_details) {
            for (const errData of this.value[key]?.statistic?.error_details) {
              temp.push({
                index,
                show: false,
                error_message: errData.error_message,
                error_field: errData.error_field,
                contact_ids: errData.contact_ids,
              });

              index++;
            }
          }

          this.google_ads_conversion_action[key]['statistic']['error_details'] = temp;
        }
      }

      this.initial_google_ads_conversion_action = structuredClone(this.google_ads_conversion_action);
    }

    if (this.value?.statistic?.calls_error_details) {
      let index = 0;
      for (const errData of this.value?.statistic?.calls_error_details) {
        this.calls_errors.push({
          index,
          show: false,
          error_message: errData.error_message,
          error_field: errData.error_field,
          contact_ids: errData.contact_ids,
        });

        index++;
      }
    }

    if (this.value?.statistic?.gclid_error_details) {
      let index = 0;
      for (const errData of this.value?.statistic?.gclid_error_details) {
        this.gclid_errors.push({
          index,
          show: false,
          error_message: errData.error_message,
          error_field: errData.error_field,
          contact_ids: errData.contact_ids,
        });

        index++;
      }
    }
  }

  refresh(params: ICellRendererParams) {
    this.params = params;
    return true;
  }

  showPopup(type: string, event: any, popover: any) {
    popover.toggle(event);
  }

  updateValue(entry: string, value: any) {
    const data = {
      platform_id: this.params.data.platform_id,
      entry,
      field: 'google_ads_conversion_action',
      value: value === '' ? null : value,
    };

    const rowNode = this.params.api.getRowNode(this.params.data._id)!;
    const vv = rowNode.data.google_ads_conversion_action || null;

    if (vv !== null) {
      vv[entry].sync = value;
    }

    // console.log('vv', vv);
    // console.log('rowNode', rowNode);
    // console.log('():::', { ...this.params.data, ...{google_ads_conversion_action: vv, row_is_blocked: true } });

    rowNode.setData({ ...this.params.data, ...{ google_ads_conversion_action: vv, row_is_blocked: true } });

    this.apiService.overviewUpdateValue(data).subscribe({
      next: (res: any) => {
        this.params.api.applyTransaction({
          update: [res],
        });
        this.messageService.add({
          severity: 'success',
          summary: 'Overview Update',
          detail: 'Data Successfully Updated',
        });

        const rowNode = this.params.api.getRowNode(this.params.data._id)!;
        rowNode.setData({ ...res, row_is_blocked: false });
      },
      error: (err) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Overview Update Error!',
          detail: err.message,
        });

        if (vv) {
          vv.sync = this.initial_google_ads_conversion_action[entry].sync;
        }

        const rowNode = this.params.api.getRowNode(this.params.data._id)!;
        rowNode.setData({
          ...this.params.data,
          ...{ vv, row_is_blocked: false },
        });
      },
    });
  }

  onChangeValue(entry: string): void {
    if (!this.ppc_project_is_active) {
      this.messageService.add({
        severity: 'error',
        summary: 'Overview Update',
        detail: 'No active ppc projects',
      });

      setTimeout(() => {
        this.google_ads_conversion_action[entry].sync = this.initial_google_ads_conversion_action[entry].sync;
      }, 150);
    } else {
      this.confirmationService.confirm({
        message: 'This action will update both the default table and analytics. Do you want to proceed?',
        header: 'Confirmation',
        closable: false,
        closeOnEscape: false,
        // icon: 'pi pi-exclamation-triangle',
        rejectButtonProps: {
          label: 'Cancel',
          severity: 'secondary',
          outlined: true,
        },
        acceptButtonProps: {
          label: 'Save',
        },
        accept: () => {
          this.updateValue(entry, this.google_ads_conversion_action[entry].sync);
        },
        reject: () => {
          this.google_ads_conversion_action[entry].sync = this.initial_google_ads_conversion_action[entry].sync;
        },
      });
    }
  }

  copyToClipboard(data: string) {
    navigator.clipboard.writeText(data);
  }

  protected readonly String = String;
  protected readonly Object = Object;
  protected readonly Number = Number;
}
