import { Component, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { StorageService } from '../../../../services/storage.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ICellRendererParams } from 'ag-grid-community';
import { Select } from 'primeng/select';
import { FormsModule } from '@angular/forms';
import { environment } from '../../../../../environments/environment';
import { ConfirmDialog } from 'primeng/confirmdialog';
import { Tooltip } from 'primeng/tooltip';
import { ApiService } from '../../../../services/api.service';

@Component({
  selector: 'app-overview-client-renderer',
  imports: [Select, FormsModule, ConfirmDialog, Tooltip],
  templateUrl: './overview-client-renderer.component.html',
  styleUrl: './overview-client-renderer.component.scss',
  providers: [ConfirmationService],
})
export class OverviewClientRendererComponent {
  private params: any;
  public apiService: ApiService = inject(ApiService);
  private storageService: StorageService = inject(StorageService);
  public messageService: MessageService = inject(MessageService);

  public requiredField: boolean = false;
  showSelect: boolean = false;
  values: any[] = [];
  value: any;
  initialValue: any;

  constructor(private confirmationService: ConfirmationService) {}

  updateValue(value: any) {
    const data = {
      platform_id: this.params.data.platform_id,
      field: this.params.colDef.field,
      value: value === '' ? null : value,
    };

    if (this.params.colDef.field === 'client_name') {
      data.value = {
        _id: this.storageService.clients.find((c: any) => c.label === value)._id,
        name: value,
        projects: this.params.data.projects,
      };
    }

    const rowNode = this.params.api.getRowNode(this.params.data._id)!;
    rowNode.setData({ ...this.params.data, ...{ [this.params.colDef.field]: value, row_is_blocked: true } });

    this.apiService.overviewUpdateValue(data).subscribe({
      next: async (res: any) => {
        this.params.api.applyTransaction({
          update: [res],
        });
        this.messageService.add({
          severity: 'success',
          summary: 'Overview Update',
          detail: 'Data Successfully Updated',
        });

        const rowNode = this.params.api.getRowNode(this.params.data._id)!;
        rowNode.setData({ ...res, ...{ [this.params.colDef.field]: value, row_is_blocked: false } });

        await this.apiService.getNewClients();
      },
      error: (err) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Overview Update Error!',
          detail: err.message,
        });
      },
    });
  }

  agInit(params: any): void {
    this.params = params;
    this.value = params.value;
    this.initialValue = params.value;
    this.values = [...this.storageService.clients];

    if (!this.values.find((f: any) => f.label === this.params.data.client_name)) {
      this.values.push({
        label: this.params.data.client_name,
        value: this.params.data.client_name,
      });
    }

    if (this.value === 'Temporary Client') {
      this.requiredField = true;
    }

    if (this.params.data.url.includes('placeholder.com')) {
      this.showSelect = true;
    }
  }

  refresh(params: ICellRendererParams) {
    this.params = params;
    return true;
  }

  onDropdownClick(event: any) {}

  onChangeValue(event: any): void {
    this.confirmationService.confirm({
      message: 'This action will update both the default table and analytics. Do you want to proceed?',
      header: 'Confirmation',
      closable: false,
      closeOnEscape: false,
      rejectButtonProps: {
        label: 'Cancel',
        severity: 'secondary',
        outlined: true,
      },
      acceptButtonProps: {
        label: 'Save',
      },
      accept: () => {
        this.updateValue(event.value);
      },
      reject: () => {
        this.value = this.initialValue;
      },
    });
  }
}
