import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { Button } from 'primeng/button';

@Component({
  selector: 'app-overview-client-access-renderer',
  imports: [Button],
  templateUrl: './overview-client-access-renderer.component.html',
  styleUrl: './overview-client-access-renderer.component.scss',
})
export class OverviewClientAccessRendererComponent {
  private params: any;
  public value: any = {};

  agInit(params: any): void {
    this.params = params;
    this.value = params.value;
  }

  copy(value: string) {
    navigator.clipboard.writeText(value);
  }

  refresh(params: ICellRendererParams) {
    this.params = params;
    return true;
  }
}
