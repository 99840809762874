import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { Tooltip } from 'primeng/tooltip';

@Component({
  selector: 'app-overview-goal-renderer',
  imports: [Tooltip],
  templateUrl: './overview-goal-renderer.component.html',
  styleUrl: './overview-goal-renderer.component.scss',
})
export class OverviewGoalRendererComponent {
  protected params: any = {};
  protected goals: any = {};
  requiredField: boolean = false;
  public seo_project_is_active: boolean = false;


  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.refresh(params);
    this.seo_project_is_active = this.params.data.projects?.findIndex((p: any) => p.type === 'PPC' && p.status === 'active') !== -1;

    if (!this.params.data.goals?.target && this.seo_project_is_active) {
      this.requiredField = true;
    }
  }
  refresh(params: any): boolean {
    this.goals = params?.data?.goals;
    return true;
  }
}
