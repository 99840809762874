<div class="select-wrap">
  @if (field === 'practice_area' && isLegal || field !== 'practice_area') {
    <p-select
      #leadStatus
      [options]="values"
      [appendTo]="'body'"
      [disabled]="disabled"
      [showClear]="true"
      [filter]="showSearch"
      (onClick)="onDropdownClick($event)"
      (onChange)="onChangeValue($event)"
      [(ngModel)]="cellValue"
      scrollHeight="300px"
      [placeholder]="placeholder">
      <ng-template pTemplate="selectedItem">
        <div class="ls-wrap-selected-value">
          @if (selectedFill) {
            <span [ngStyle]="{'--overview-risk-color': selectedFill}" [attr.risk-status-color]="selectedLabel"></span>
          }
          <div>{{ selectedLabel }}</div>
        </div>
      </ng-template>
      <ng-template let-val pTemplate="item">
        <div class="ls-wrap-list-item">
          @if (val.fill) {
            <span [ngStyle]="{'--overview-risk-color': val.fill}" [attr.risk-status-color]="val.label"></span>
          }
          {{val.label}}
        </div>
      </ng-template>
    </p-select>
  }
  @if (requiredField) {
    <div [pTooltip]="warningText" tooltipPosition="top" class="warning-badge">!</div>
  }
</div>

<p-confirmdialog />
