import { Component, inject, ViewChild } from '@angular/core';
import { DatePickerModule } from 'primeng/datepicker';
import { FormsModule } from '@angular/forms';
import { Calendar } from 'primeng/calendar';
import { HttpClient } from '@angular/common/http';
import { StorageService } from '../../../../services/storage.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { environment } from '../../../../../environments/environment';
import { Tooltip } from 'primeng/tooltip';
import { ApiService } from '../../../../services/api.service';

@Component({
  selector: 'app-date-selector-renderer',
  imports: [DatePickerModule, FormsModule, Calendar, Tooltip],
  templateUrl: './date-selector-renderer.component.html',
  styleUrl: './date-selector-renderer.component.scss',
})
export class DateSelectorRendererComponent {
  public apiService: ApiService = inject(ApiService);
  public messageService: MessageService = inject(MessageService);

  private initialValue: string = '';
  public requiredField: boolean = false;
  public tooltipText: string = 'This field is required';
  public disabled: boolean = false;

  selectedDate: any | null = null; // initial value;
  submitedDate: any | null = null; // initial value;
  params: any = {};

  // options
  showIcon: boolean = true;
  minDate: Date | null = null;
  maxDate: Date | null = null;
  view: any = ''; // 'date' | 'month' | 'year'

  dateChanged: boolean = false;
  showProjectWarnings: boolean = false;


  constructor(private confirmationService: ConfirmationService) {}

  ngAfterViewInit(): void {}
  agInit(params: any): void {
    this.params = params;
    this.initialValue = params.valueFormatted;
    this.refresh(params);

    if (this.params.colDef.field === 'real_launch_date' && !this.params.data.projects.find((prj: any) => prj.type === 'DEV')) {
      this.showProjectWarnings = true;
    }

    if (!this.params.value && this.params.colDef.field !== 'real_launch_date' && this.params.colDef.field !== 'cancellation_date') {
      this.requiredField = true;
    } else if (this.params.colDef.field === 'real_launch_date') {
      const projects = this.params.data.projects;
      // console.log('❌ projects', projects);
      const archivedDevProjects = projects.find((prj: any) => prj.type === 'DEV' && prj.status === 'archived');
      if (archivedDevProjects && !this.params.value) {
        this.requiredField = true;
      }
      // не обязательное для активных проектов (! ставить не надо), при этом у архивных DEV проектов нужно валидировать (то есть показываем информер)
    } else if (this.params.colDef.field === 'cancellation_date') {
      const projects = this.params.data.projects;
      const archivedProjects = projects.filter((prj: any) => prj.status === 'archived');
      const activeProjects = projects.filter((prj: any) => prj.status === 'active');

      if (archivedProjects?.length && projects?.length && archivedProjects?.length === projects?.length && !this.params.value) {
        this.requiredField = true;
      }

      if (activeProjects?.length && this.params.value) {
        this.requiredField = true;
        this.tooltipText = 'Error. This record has an active project';
      }
    }

    if (this.params.data.client_name === 'Temporary Client') {
      this.disabled = true;
      this.requiredField = true;
      this.tooltipText = 'Before selecting you need to set Client Name';
    }

    if (this.params.colDef.field === 'problem_forms_check_in_date') {
      this.requiredField = false;
    }

    if (this.params.colDef.field === 'project_settings_check_in_date') {
      this.requiredField = false;
    }

  }
  refresh(params: any): boolean {
    // set default values or override from params
    this.dateChanged = false;
    const dateValue = new Date(params.value);
    if (isNaN(dateValue.getTime()) || dateValue.getTime() === 0) {
      this.selectedDate = null;
    } else {
      this.selectedDate = dateValue;
    }
    this.showIcon = params.showIcon || true;
    this.view = params.view || 'date';
    this.minDate = params.minDate || null;
    this.maxDate = params.maxDate || null;
    return true;
  }

  updateValue(value: any) {
    const data = {
      platform_id: this.params.data.platform_id,
      field: this.params.colDef.field,
      value: value === '' ? null : value,
    };

    const rowNode = this.params.api.getRowNode(this.params.data._id)!;
    rowNode.setData({ ...this.params.data, ...{ [this.params.colDef.field]: value, row_is_blocked: true } });

    this.apiService.overviewUpdateValue(data).subscribe({
      next: (res: any) => {
        this.params.api.applyTransaction({
          update: [res],
        });
        this.messageService.add({
          severity: 'success',
          summary: 'Overview Update',
          detail: 'Data Successfully Updated',
        });

        const rowNode = this.params.api.getRowNode(this.params.data._id)!;
        rowNode.setData({ ...res, ...{ [this.params.colDef.field]: value, row_is_blocked: false } });
      },
      error: (err) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Overview Update Error!',
          detail: err.message,
        });

        const rowNode = this.params.api.getRowNode(this.params.data._id)!;
        rowNode.setData({
          ...this.params.data,
          ...{ [this.params.colDef.field]: this.initialValue, row_is_blocked: false },
        });
      },
    });
  }

  getValue() {
    if (!this.dateChanged) {
      return this.params.value;
    }
    if (!this.selectedDate) {
      return null;
    }
    // return this.dateFormat[this.view].get(this.selectedDate);
  }
  isPopup(): boolean {
    return false;
  }

  onDateChange(clearClick: boolean = false): void {
    this.dateChanged = true;

    if (clearClick) {
      this.selectedDate = undefined;
    }

    this.confirmationService.confirm({
      // target: event.target as EventTarget,
      message: 'This action will update both the default table and analytics. Do you want to proceed?',
      header: 'Confirmation',
      closable: false,
      closeOnEscape: false,
      // icon: 'pi pi-exclamation-triangle',
      rejectButtonProps: {
        label: 'Cancel',
        severity: 'secondary',
        outlined: true,
      },
      acceptButtonProps: {
        label: 'Save',
      },
      accept: () => {
        // const [s_month, s_day, s_year] = this.selectedDate.split('/');
        // this.submitedDate = `${s_year}-${s_month.padStart(2, '0')}-${s_day.padStart(2, '0')}T00:00:00`;
        this.updateValue(this.selectedDate);
      },
      reject: () => {
        this.selectedDate = this.initialValue;
      },
    });

  }

  onPanelHide() {
    // this.params.api.stopEditing();
  }
}
