<p-datepicker
  [(ngModel)]="date"
  [dateFormat]="'mm/dd/yy'"
  [placeholder]="'--/--/----'"
  [iconDisplay]="'input'"
  [showIcon]="true"
  [autofocus]="false"
  [showOnFocus]="true"
  [readonlyInput]="true"
  [appendTo]="'body'"
  [showButtonBar]="false"
  inputId="icondisplay"
  (onSelect)="onDateChange()"
/>
