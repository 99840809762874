@if (showSelect) {
  <div class="select-wrap">
    <p-select
      [options]="values"
      [appendTo]="'body'"
      [showClear]="false"
      (onClick)="onDropdownClick($event)"
      (onChange)="onChangeValue($event)"
      [(ngModel)]="value"
      scrollHeight="300px"
      placeholder="Select Client">
    </p-select>
    @if (requiredField) {
      <div pTooltip="This field is required" tooltipPosition="top" class="warning-badge">!</div>
    }
  </div>

} @else {
  {{value}}
}

<p-confirmdialog />
