@if (value) {
  <div class="deals_item cl1">
    <span class="label">Close Won</span>
    <span class="value">{{value.total_closed_won?.toLocaleString('en', { maximumFractionDigits: 2 })}}</span>
  </div>
  <div class="deals_item cl2">
    <span class="label">Filled Values</span>
    <span class="value">{{value.total_case_value_count?.toLocaleString('en', { maximumFractionDigits: 2 })}}</span>
  </div>

  <div class="deals_item cl3">
    <span class="label">Avg Value</span>
    @if (avgVal) {
      <span class="value">${{avgVal?.toLocaleString('en', { maximumFractionDigits: 2 })}}</span>
    } @else {
      <span class="value">-</span>
    }

  </div>
}
