import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-overview-dev-timeframe-renderer',
  imports: [],
  templateUrl: './overview-dev-timeframe-renderer.component.html',
  styleUrl: './overview-dev-timeframe-renderer.component.scss'
})
export class OverviewDevTimeframeRendererComponent {
  protected params: any = {};
  protected dev_timeframe: any = [];
  public existDevProject: boolean = false;

  constructor() { }
  agInit(params: ICellRendererParams): void {
    this.params = params;
    for (const prj of this.params.data.projects) {
      if (prj.type.toLowerCase().includes('dev')) {
        this.existDevProject = true;
      }
    }
    this.refresh(params);
  }
  refresh(params: any): boolean {
    this.dev_timeframe = params?.data?.dev_timeframe;
    return true;
  }
}
