import { Component, inject } from '@angular/core';
import { Select } from 'primeng/select';
import { HttpClient } from '@angular/common/http';
import { StorageService } from '../../../../services/storage.service';
import { ICellRendererParams } from 'ag-grid-community';
import { FormsModule } from '@angular/forms';
import { environment } from '../../../../../environments/environment';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Tooltip } from 'primeng/tooltip';
import { ApiService } from '../../../../services/api.service';

@Component({
  selector: 'app-dropdown-selector-renderer',
  imports: [Select, FormsModule, Tooltip],
  templateUrl: './dropdown-selector-renderer.component.html',
  styleUrl: './dropdown-selector-renderer.component.scss',
})
export class DropdownSelectorRendererComponent {
  private params: any;
  public apiService: ApiService = inject(ApiService);
  private storageService: StorageService = inject(StorageService);
  public messageService: MessageService = inject(MessageService);

  public selectedLabel: string = '';
  private initialValue: any = {};

  public warningText: string = 'This field is required';
  public disabled: boolean = false;

  public values: any[] = [];
  public cellValue: any = {};
  public requiredField: boolean = false;

  seo_activity_tag_id: string = '';

  constructor(private confirmationService: ConfirmationService) {}

  agInit(params: any): void {
    this.params = params;
    this.values = params.values;
    this.cellValue = params.value;
    this.initialValue = params.value;

    const isSeoField = this.params.colDef.field.includes('seo');
    const isPpcField = this.params.colDef.field.includes('ppc');
    const existingSeoProject: boolean = this.params.data.projects.find((p: any) => p.type === 'SEO');
    const existingPpcProject: boolean = this.params.data.projects.find((p: any) => p.type === 'PPC');

    // console.log('✳️this.params', this.params.data);
    // console.log('✳️storageService.staffs', this.storageService.staffs);

    if (isSeoField && existingSeoProject && !this.cellValue) {
      this.requiredField = true;
    } else if (isPpcField && existingPpcProject && !this.cellValue) {
      this.requiredField = true;
    } else if (!isSeoField && !isPpcField && !this.cellValue) {
      this.requiredField = true;
    }

    if (this.params.data.client_name === 'Temporary Client' && this.params.colDef.field.includes('.name')) {
      this.disabled = true;
      this.requiredField = true;
      this.warningText = 'Before selecting you need to set Client Name';
    } else {
      this.disabled = false;
      this.warningText = 'This field is required';
    }
  }

  onDropdownClick(event: any): void {}

  findTagForDelete(staff_id: string) {
    if (this.storageService.staffs.service_specs.find((s: any) => s._id === staff_id)) {
      this.seo_activity_tag_id = '65b0df0a5d05811a5f053480'; // service_specs
    } else if (this.storageService.staffs.blog_specs.find((s: any) => s._id === staff_id)) {
      this.seo_activity_tag_id = '65b0df0a5d05811a5f053481'; // blog_specs
    } else if (this.storageService.staffs.seo_strategy.find((s: any) => s._id === staff_id)) {
      this.seo_activity_tag_id = '65b0df0a5d05811a5f05347e'; // seo_strategy
    } else if (this.storageService.staffs.seo_supervisor.find((s: any) => s._id === staff_id)) {
      this.seo_activity_tag_id = '67c82ca1dc14336935e0aa56'; // seo_supervisor
    }
  }


  findSeoActivityTag(staff_id: string) {
    if (this.storageService.staffs.service_specs.find((s: any) => s._id === staff_id)) {
      this.seo_activity_tag_id = '65b0df0a5d05811a5f053480'; // service_specs
    } else if (this.storageService.staffs.blog_specs.find((s: any) => s._id === staff_id)) {
      this.seo_activity_tag_id = '65b0df0a5d05811a5f053481'; // blog_specs
    } else if (this.storageService.staffs.seo_strategy.find((s: any) => s._id === staff_id)) {
      this.seo_activity_tag_id = '65b0df0a5d05811a5f05347e'; // seo_strategy
    } else if (this.storageService.staffs.seo_supervisor.find((s: any) => s._id === staff_id)) {
      this.seo_activity_tag_id = '67c82ca1dc14336935e0aa56'; // seo_supervisor
    }
  }

  updateValue(value: any) {
    let val = {
      _id: value,
      name: '',
    };
    // console.log('---', this.params);
    console.log('this.initialValue,', this.initialValue);

    if (this.params.colDef.field.includes('am.')) {
      val.name = this.storageService.staffs.am.find((s: any) => s._id === value)?.name;
    } else if (this.params.colDef.field.includes('am_supervisor.')) {
      val.name = this.storageService.staffs.am.find((s: any) => s._id === value)?.name;
    } else if (this.params.colDef.field.includes('pm.')) {
      val.name = this.storageService.staffs.pm.find((s: any) => s._id === value)?.name;
    } else if (this.params.colDef.field.includes('ae.')) {
      val.name = this.storageService.staffs.ae.find((s: any) => s._id === value)?.name;
    } else if (this.params.colDef.field.includes('ppc.')) {
      val.name = this.storageService.staffs.ppc_management.find((s: any) => s._id === value)?.name;
    } else if (this.params.colDef.field.includes('seo_supervisor.')) {
      if (value === null) {
        // this.findSeoActivityTag(this.initialValue);
      } else {
        // this.findSeoActivityTag(value);
      }

      this.seo_activity_tag_id = '67c82ca1dc14336935e0aa56';

      val.name = this.storageService.staffs.seo_supervisor_renderer_list.find((s: any) => s._id === value)?.name;

    } else if (this.params.colDef.field.includes('dev.')) {
      val.name = this.storageService.staffs.development.find((s: any) => s._id === value)?.name;
    } else if (this.params.colDef.field.includes('des.')) {
      val.name = this.storageService.staffs.design.find((s: any) => s._id === value)?.name;
    } else if (this.params.colDef.field.includes('ppc_supervisor.')) {
      val.name = this.storageService.staffs.ppc_management.find((s: any) => s._id === value)?.name;
    } else if (this.params.colDef.field.includes('seo.')) {
      this.seo_activity_tag_id = '65b0de633d07af43ee035883';
      // if (value === null) {
      //   this.findSeoActivityTag(this.initialValue);
      // } else {
      //   this.findSeoActivityTag(value);
      // }
      // if (this.storageService.staffs.service_specs.find((s: any) => s._id === value)) {
      //   this.seo_activity_tag_id = '65b0df0a5d05811a5f053480'; // service_specs
      // } else if (this.storageService.staffs.blog_specs.find((s: any) => s._id === value)) {
      //   this.seo_activity_tag_id = '65b0df0a5d05811a5f053481'; // blog_specs
      // } else if (this.storageService.staffs.seo_strategy.find((s: any) => s._id === value)) {
      //   this.seo_activity_tag_id = '65b0df0a5d05811a5f05347e'; // seo_strategy
      // }

      val.name = this.storageService.staffs.seo_renderer_list.find((s: any) => s._id === value)?.name;
      // val.name = this.storageService.staffs.service_specs.find((s: any) => s._id === value)?.name || this.storageService.staffs.blog_specs.find((s: any) => s._id === value)?.name;
    }

    const data: any = {
      platform_id: this.params.data.platform_id,
      field: this.params.colDef.field
        .replace('pm.name', 'pm')
        .replace('am.name', 'am')
        .replace('ae.name', 'ae')
        .replace('dev.name', 'dev')
        .replace('des.name', 'des')
        .replace('seo.name', 'seo')
        .replace('seo_supervisor.name', 'seo_supervisor')
        .replace('am_supervisor.name', 'am_supervisor')
        .replace('ppc_supervisor.name', 'ppc_supervisor')
        .replace('ppc.name', 'ppc'),
      value: value,
    };

    console.log('**************', this.params);

    if (this.params.colDef.field.includes('seo.') || this.params.colDef.field.includes('seo_supervisor')) {
      data.activity_tag_id = this.seo_activity_tag_id;
    }

    const rowNode = this.params.api.getRowNode(this.params.data._id)!;
    rowNode.setData({
      ...this.params.data,
      ...{
        [this.params.colDef.field
          .replace('pm.name', 'pm')
          .replace('am.name', 'am')
          .replace('ppc.name', 'ppc')
          .replace('dev.name', 'dev')
          .replace('des.name', 'des')
          .replace('seo.name', 'seo')
          .replace('seo_supervisor.name', 'seo_supervisor')
          .replace('am_supervisor.name', 'am_supervisor')
          .replace('ppc_supervisor.name', 'ppc_supervisor')
          .replace('ae.name', 'ae')]: val,
        row_is_blocked: true,
      },
    });

    this.apiService.overviewUpdateValue(data).subscribe({
      next: (res: any) => {
        this.params.api.applyTransaction({
          update: [res],
        });
        this.messageService.add({
          severity: 'success',
          summary: 'Overview Update',
          detail: 'Data Successfully Updated',
        });

        const rowNode = this.params.api.getRowNode(this.params.data._id)!;
        rowNode.setData({
          ...res,
          ...{
            [this.params.colDef.field
              .replace('pm.name', 'pm')
              .replace('am.name', 'am')
              .replace('ppc.name', 'ppc')
              .replace('dev.name', 'dev')
              .replace('des.name', 'des')
              .replace('seo.name', 'seo')
              .replace('seo_supervisor.name', 'seo_supervisor')
              .replace('am_supervisor.name', 'am_supervisor')
              .replace('ppc_supervisor.name', 'ppc_supervisor')
              .replace('ae.name', 'ae')]: val,
            row_is_blocked: false,
          },
        });
      },
      error: (err) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Overview Update Error!',
          detail: err.message,
        });

        const rowNode = this.params.api.getRowNode(this.params.data._id)!;
        rowNode.setData({
          ...this.params.data,
          ...{ [this.params.colDef.field]: this.initialValue, row_is_blocked: false },
        });
      },
    });
  }

  onChangeValue(event: any): void {
    console.log('onChangeValue', event);
    this.confirmationService.confirm({
      // target: event.target as EventTarget,
      message: 'This action will update both the default table and analytics. Do you want to proceed?',
      header: 'Confirmation',
      closable: false,
      closeOnEscape: false,
      // icon: 'pi pi-exclamation-triangle',
      rejectButtonProps: {
        label: 'Cancel',
        severity: 'secondary',
        outlined: true,
      },
      acceptButtonProps: {
        label: 'Save',
      },
      accept: () => {
        // const [s_month, s_day, s_year] = this.selectedDate.split('/');
        // this.submitedDate = `${s_year}-${s_month.padStart(2, '0')}-${s_day.padStart(2, '0')}T00:00:00`;
        this.updateValue(event.value);
      },
      reject: () => {
        this.cellValue = this.initialValue;
      },
    });
  }

  refresh(params: ICellRendererParams) {
    this.params = params;
    return true;
  }
}
