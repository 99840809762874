<!--<button (click)="test()">test</button>-->
<main class="main overview">
  <div class="overview__container">
    <div class="top-area">
      <div class="search-input">
        <div class="icon-search">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M17.5 17.5L13.875 13.875M15.8333 9.16667C15.8333 12.8486 12.8486 15.8333 9.16667 15.8333C5.48477 15.8333 2.5 12.8486 2.5 9.16667C2.5 5.48477 5.48477 2.5 9.16667 2.5C12.8486 2.5 15.8333 5.48477 15.8333 9.16667Z" stroke="#7986FF" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
        <input
          #search
          type="text"
          placeholder="Search projects..."
          pInputText
          [(ngModel)]="searchText"
          (input)="searchHandler($event)"
          variant="filled" />

        @if (searchText.length) {
          <div class="icon-clear" (click)="clearSearchValue()">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M15 5L5 15M5 5L15 15" stroke="#667085" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
        }
      </div>

      <div class="top-area__rt">
        <p-select
          [options]="gridColumnPresetsList"
          [(ngModel)]="gridColumnPresetSelected"
          (onChange)="onGridChangePreset($event)"
          optionLabel="label"
          optionValue="value"
          placeholder="" />

        <app-button
          label="Reset filters"
          [disabled]="disableResetFilters"
          (click)="resetFilters()"/>
<!--        pi-filter-slash-->

        <app-button
          label="Reset column state"
          [disabled]="disabledResetColumnButton"
          (click)="resetColumnState()"/>
<!--        <p-button (click)="resetColumnState()" icon="pi pi-sparkles" aria-label="Reset column state" />-->
      </div>
    </div>


    <div class="fm1">
      @if (activeFilters.length > 0) {
        <div class="active-filters-container">
          @for (filter of activeFilters; track filter.name) {
            <div class="active-filters-item">
              @if (filter.value?.length > 0) {
                <span>{{filter.name}}:</span>
                <div class="active-filters-item__value">{{filter.value}}</div>
              } @else {
                <span>{{filter.name}}</span>
              }

              <button pRipple (click)="removeFilter(filter)">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M15 5L5 15M5 5L15 15" stroke="#667085" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </button>
            </div>
          }
        </div>
      } @else {
        <div></div>
      }

      @if (filterInfoString.length > 0) {
        <div class="filter-info">{{filterInfoString}}</div>
      } @else {
        <div class="filter-info"><p-skeleton width="350px" height="20px" [style]="{'margin-left': 'auto'}"/></div>
      }
    </div>

<!--    <button (click)="test123()">clll</button>-->
    @if (showSearchEverywhereMessage) {
      <div class="filter-info-filter-message">Searching within selected filters only. <span (click)="onSearchEverywhere()">Search everywhere</span></div>
    }
<!--    [suppressColumnVirtualisation]="true"-->
    @if (gridReady) {
      <ag-grid-angular
        [localeText]="localeText"
        [rowData]="rowData"
        [animateRows]="false"
        [sideBar]="sideBar"
        [pivotMode]="false"
        [statusBar]="statusBar"
        [getRowId]="getRowId"
        [rowClassRules]="rowClassRules"

        [rowBuffer]="2"
        [defaultColDef]="defaultColDef"
        [autoGroupColumnDef]="autoGroupColumnDef"
        [columnDefs]="colDefs"
        (cellEditingStopped)="onCellEditingStopped($event)"
        (sortChanged)="columnEverythingChanged($event)"
        (columnMoved)="columnEverythingChanged($event)"
        (columnResized)="columnEverythingChanged($event)"
        (columnPinned)="columnEverythingChanged($event)"
        (columnVisible)="columnEverythingChanged($event)"
        (filterChanged)="filterChanged($event)"
        (gridReady)="onGridReady($event)"
      />
    }
  </div>
</main>
<p-confirmDialog />

