import { Component, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { StorageService } from '../../../../services/storage.service';
import { MessageService } from 'primeng/api';
import { Tooltip } from 'primeng/tooltip';

@Component({
  selector: 'app-overview-website-url-renderer',
  imports: [Tooltip],
  templateUrl: './overview-website-url-renderer.component.html',
  styleUrl: './overview-website-url-renderer.component.scss',
})
export class OverviewWebsiteUrlRendererComponent {
  params: any = {};
  value: any = null;

  invalidUrl: boolean = false;

  agInit(params: any): void {
    this.params = params;
    this.value = params.value;

    if (this.value.includes('placeholder.com')) {
      this.invalidUrl = true;
    } else {
      this.invalidUrl = !this.isValidURL(this.value);
    }
  }

  refresh(params: any): boolean {
    return true;
  }

  isValidURL(url: any) {
    const regex = /^[a-zA-Z0-9][a-zA-Z0-9.-]*\.[a-zA-Z]{2,}$/;
    return regex.test(url);
  }

}
