import { Component, Inject, inject, LOCALE_ID, OnInit } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import {
  ColDef,
  GridApi,
  GridReadyEvent,
  ICellRendererParams, IDateFilterParams, RowClassRules,
  SideBarDef,
  StatusPanelDef, ValueFormatterParams,
} from 'ag-grid-community';

import { AllCommunityModule, ModuleRegistry } from 'ag-grid-community';


import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ViewCellRenderComponent } from '../../components/ui/grid/view-cell-render/view-cell-render.component';
import { CruxRendererComponent } from '../../components/ui/grid/crux-renderer/crux-renderer.component';
import {
  OverviewFormsRendererComponent
} from '../../components/ui/grid/overview-forms-renderer/overview-forms-renderer.component';
import { AutoComplete } from 'primeng/autocomplete';
import { Button } from 'primeng/button';
import { ButtonComponent } from '../../components/ui/button/button.component';
import { Checkbox } from 'primeng/checkbox';
import { DatePicker } from 'primeng/datepicker';
import { FormsModule } from '@angular/forms';
import { InputText } from 'primeng/inputtext';
import { MultiSelect } from 'primeng/multiselect';
import { Popover } from 'primeng/popover';
import { Ripple } from 'primeng/ripple';
import { Select } from 'primeng/select';
import { Skeleton } from 'primeng/skeleton';
import { CustomStatusBarComponent } from '../../components/ui/grid/custom-status-bar/custom-status-bar.component';
// import { StatusBarModule } from '@ag-grid-enterprise/status-bar';
import {
  OverviewProjectsRendererComponent
} from '../../components/ui/grid/overview-projects-renderer/overview-projects-renderer.component';
import { FilterRendererComponent } from '../../components/ui/grid/filter-renderer/filter-renderer.component';
import {
  OverviewTotalMqlsRendererComponent
} from '../../components/ui/grid/overview-total-mqls-renderer/overview-total-mqls-renderer.component';
import {
  OverviewGoalRendererComponent
} from '../../components/ui/grid/overview-goal-renderer/overview-goal-renderer.component';
import { AllEnterpriseModule } from 'ag-grid-enterprise';
import { AgChartsCommunityModule } from 'ag-charts-enterprise';
import {
  OverviewChartRendererComponent
} from '../../components/ui/grid/overview-chart-renderer/overview-chart-renderer.component';
import {
  OverviewDevTimeframeRendererComponent
} from '../../components/ui/grid/overview-dev-timeframe-renderer/overview-dev-timeframe-renderer.component';
import {
  LeadStatusCellRenderComponent
} from '../../components/ui/grid/lead-status-cell-render/lead-status-cell-render.component';
import {
  OverviewReportingServiceRendererComponent
} from '../../components/ui/grid/overview-reporting-service-renderer/overview-reporting-service-renderer.component';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ConfirmDialog } from 'primeng/confirmdialog';
import {
  DateSelectorRendererComponent
} from '../../components/ui/grid/date-selector-renderer/date-selector-renderer.component';
import {
  DropdownSelectorRendererComponent
} from '../../components/ui/grid/dropdown-selector-renderer/dropdown-selector-renderer.component';
import { StorageService } from '../../services/storage.service';
import {
  OverviewRiskRendererComponent
} from '../../components/ui/grid/overview-risk-renderer/overview-risk-renderer.component';
import {
  OverviewBrandRendererComponent
} from '../../components/ui/grid/overview-brand-renderer/overview-brand-renderer.component';
import {
  OverviewClientAccessRendererComponent
} from '../../components/ui/grid/overview-client-access-renderer/overview-client-access-renderer.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  OverviewClientRendererComponent
} from '../../components/ui/grid/overview-client-renderer/overview-client-renderer.component';
import {
  OverviewWebsiteUrlRendererComponent
} from '../../components/ui/grid/overview-website-url-renderer/overview-website-url-renderer.component';
import { ApiService } from '../../services/api.service';
import { formatDate } from '@angular/common';
import { AG_GRID_LOCALE_EN } from '@ag-grid-community/locale';
import { CustomDateFilterComponent } from '../../components/ui/grid/custom-date-filter/custom-date-filter.component';
import {
  OverviewGoogleAdsConversionActionRendererComponent
} from '../../components/ui/grid/overview-google-ads-conversion-action-renderer/overview-google-ads-conversion-action-renderer.component';
import {
  OverviewDealsStatsRendererComponent
} from '../../components/ui/grid/overview-deals-stats-renderer/overview-deals-stats-renderer.component';
import {
  OverviewGoogleAdsConversionActionV2RendererComponent
} from '../../components/ui/grid/overview-google-ads-conversion-action-v2-renderer/overview-google-ads-conversion-action-v2-renderer.component';
import {
  OverviewFormsV2RendererComponent
} from '../../components/ui/grid/overview-forms-v2-renderer/overview-forms-v2-renderer.component';

// ModuleRegistry.registerModules([
// SideBarModule,
// FiltersToolPanelModule,
// ColumnsToolPanelModule,
// ClientSideRowModelModule,
// SparklinesModule,
// SetFilterModule,
// StatusBarModule ]);
// ModuleRegistry.registerModules([ AllCommunityModule, AllEnterpriseModule ]);
ModuleRegistry.registerModules([
  AllEnterpriseModule.with(AgChartsCommunityModule),
]);

const formatValue = (value: string) => {
  return value
    ?.split('_')
    ?.map((word: string) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    ?.join(' ');
};

const kpiSparklinesTooltipRenderer = (params: any) => {
  const { yValue, xValue } = params;
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const [month, year] = xValue.toString().split('_');
  return {
    title: `${months[parseInt(month)]} ${year}`,
    content: `<b>${yValue}</b>`
  };
}

const kpiSparklinesDataFormatter = (data: any) => {
  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth();
  return data.filter((item: any) => {
    const [month, year] = item.period.split('_').map(Number);
    const itemTotalMonths = year * 12 + month;
    const currentTotalMonths = currentYear * 12 + currentMonth;
    const monthsDiff = currentTotalMonths - itemTotalMonths;
    return monthsDiff >= 0 && monthsDiff < 12;
  });
}

@Component({
  selector: 'app-overview',
  imports: [AgGridAngular, ButtonComponent, FormsModule, InputText, Ripple, Skeleton, ConfirmDialog, Select],
  templateUrl: './overview.component.html',
  styleUrl: './overview.component.scss',
  providers: [ConfirmationService],
})
export class OverviewComponent implements OnInit {
  public messageService: MessageService = inject(MessageService);
  public apiService: ApiService = inject(ApiService);
  private storageService: StorageService = inject(StorageService);

  localeText: { [key: string]: string; } = AG_GRID_LOCALE_EN;

  public gridReady: boolean = false;
  public activeFilters: any = [];
  public disableResetFilters: boolean = true;
  public searchText: string = '';
  private searchHandlerTimeout: any;
  public showSearchEverywhereMessage: boolean = false;
  public filterInfoString: string = '';

  // Add all new columns to full_view
  public gridColumnPresetsList: any = [
    {
      label: 'Full view',
      value: 'full_view',
      columns: [
        'details',
        'abbr',
        'client_name',
        'project_settings_check_in_date',
        'status',
        'industry',
        'practice_area',
        'projects',
        'url',
        'kick_off_date',
        'sign_in_date',
        'total_mqls.y_y.difference',
        'total_mqls.m_m.difference',
        'goals',
        'charts.total_traffic',
        'charts.total_unique_conversions',
        'charts.total_mqls',
        'charts.projected_roi',
        'seo_budget',
        'charts.seo_budget_trend',
        'ppc_budget',
        'charts.ppc_budget_trend',
        'dev_budget',
        'reconciliation',
        'real_launch_date',
        'cms',
        'dev_timeframe',
        'forms',
        'problem_forms_check_in_date',
        'crux',
        'ae.name',
        'am.name',
        'am_supervisor.name',
        'pm.name',
        'seo.name',
        'seo_supervisor.name',
        'ppc.name',
        'ppc_supervisor.name',
        'dev.name',
        'des.name',
        'reporting_service',
        'brand',
        'client_access',
        'risk_factor',
        'risk_reason',
        'icp',
        'cancellation_date',
        'churn_reason',
        'crm',
        'churn_months',
        'active_months',
        'google_ads_conversion_action',
        'approximate_page_count',
        'deals_stats',
        'average_check',
        'closing_rate_mql_client'
      ]
    },
    {
      label: 'Churn Risk view',
      value: 'churn_risk_view',
      columns: [
        'details',
        'abbr',
        'client_name',
        'status',
        'industry',
        'practice_area',
        'projects',
        'kick_off_date',
        'seo_budget',
        'ppc_budget',
        'dev_budget',
        'reconciliation',
        'am.name',
        'pm.name',
        'risk_factor',
        'risk_reason',
        'icp',
      ]
    },
    {
      label: 'Setup view',
      value: 'setup_view',
      columns: [
        'details',
        'abbr',
        'client_name',
        'url',
        'industry',
        'practice_area',
        'projects',
        'brand',
        'reporting_service',
        'kick_off_date',
        'sign_in_date',
        'cancellation_date',
        'ae.name',
        'am.name',
        'am_supervisor.name',
        'pm.name',
        'seo.name',
        'seo_supervisor.name',
        'ppc.name',
        'ppc_supervisor.name',
        'dev.name',
        'des.name',
      ]
    }
  ];

  public gridColumnPresetSelected: any = 'full_view';
  public presetColDefs: any = [];


  public disabledResetColumnButton: boolean = true;

  public getRowId: any = (params: any) => {
    return params.data._id;
  };

  public rowClassRules: RowClassRules = {
    row_is_blocked: (params) => {
      return params.data.row_is_blocked;
    },
  };

  public staffs: any = null;

  public gridApi: GridApi | undefined;
  public sideBar: SideBarDef = {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
        },
      },
    ],
  };

  public statusBar: {
    statusPanels: StatusPanelDef[];
  } = {
    statusPanels: [{ statusPanel: CustomStatusBarComponent }],
  };

  public components = {
    agDateInput: CustomDateFilterComponent,
  }

  public defaultColDef: ColDef = {
    enablePivot: false,
    sortable: true,
    resizable: true,
    editable: false,
    filter: true,
    // floatingFilter: true,
    suppressHeaderMenuButton: true,
    menuTabs: ['filterMenuTab'],
    filterParams: {
      buttons: ['reset'],
      maxNumConditions: 1,
      refreshValuesOnOpen: true,
    },
  };

  public autoGroupColumnDef: ColDef = {
    minWidth: 200,
  };

  rowData = [];

  constructor(
    private confirmationService: ConfirmationService,
    @Inject(LOCALE_ID) public locale: string
  ) {
    const savedPreset = localStorage.getItem('overview-grid-active-preset');
    if (savedPreset) {
      this.gridColumnPresetSelected = savedPreset;
    }

    this.storageService.$overviewGridUpdate.pipe(takeUntilDestroyed()).subscribe((res: any) => {
      if (res) {
        this.gridApi?.applyTransaction({
          update: [res],
        });
      }
    });

    this.storageService.$overviewShowRowLoader.pipe(takeUntilDestroyed()).subscribe((res: any) => {
      if (res) {
        const rowNode = this.gridApi?.getRowNode(res)!;
        rowNode.setData({ ...rowNode.data, ...{ row_is_blocked: true } });
      }
    });

    this.storageService.$overviewHideRowLoader.pipe(takeUntilDestroyed()).subscribe((res: any) => {
      if (res) {
        const rowNode = this.gridApi?.getRowNode(res)!;
        rowNode.setData({ ...rowNode.data, ...{ row_is_blocked: false } });
      }
    });
  }

  colDefs: ColDef[] = [];

  priceFormatter(price: string, allowNull = false) {
    if (price === 'inapplicable') {
      return price;
    }
    const num = Number(price);
    if (isNaN(num) || num === 0) {
      if (allowNull) {
        return '$0';
      }
      return '';
    }
    return '$' + num.toLocaleString('en', { maximumFractionDigits: 2 });
  }

  dateFormatter(date: any, time = false) {
    if (!date) return '--/--/----';
    if (date === 'inapplicable') return 'inapplicable';
    const originDate = new Date(date);
    let formatter = new Intl.DateTimeFormat('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' });
    if (time) {
      formatter = new Intl.DateTimeFormat('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      });
    }
    return formatter.format(originDate);
  }

  async ngOnInit() {
    await new Promise((resolve, reject) => {
      this.apiService.getStaffsTag().subscribe({
        next: (res: any) => {
          this.storageService.staffs = res;
          this.storageService.staffs.seo_renderer_list = [...res.blog_specs, ...res.service_specs, ...res.seo_strategy];
          this.storageService.staffs.seo_supervisor_renderer_list = [...res.service_specs, ...res.seo_strategy];
          resolve(true);
        },
        error: (e) => {
          reject(true);
        },
      });
    });

    await new Promise((resolve, reject) => {
      this.apiService.getNewClients().subscribe((response) => {
        this.storageService.clients = response.map((r: any) => {
          return {
            label: r.name,
            _id: r._id,
            value: r.name,
          }
        });
        // this.storageService.clients.push({
        //   _id: '67b5c2ff9c325fa513e31ef9',
        //   label: 'Temporary Client',
        //   value: 'Temporary Client',
        // });
        resolve(true);
      });
    });

    this.presetColDefs = [
      {
        field: 'details',
        headerName: 'Details',
        maxWidth: 80,
        width: 80,
        cellRenderer: ViewCellRenderComponent,
        cellRendererParams: {
          isOverview: true,
        },
        suppressHeaderMenuButton: true,
        filter: false,
        pinned: 'left',
      },
      {
        field: 'abbr',
        headerName: 'Abbr',
        filter: 'agSetColumnFilter',
        pinned: 'left',
        maxWidth: 110,
        width: 110,
      },
      {
        field: 'client_name',
        headerName: 'Client Name',
        minWidth: 230,
        width: 230,
        cellRenderer: OverviewClientRendererComponent
      },
      {
        field: 'project_settings_check_in_date',
        headerName: 'TEMP: Project Settings Check-in Date',
        valueFormatter: (params: any) => {
          return this.dateFormatter(params.value);
        },
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: this.dateCellComparator
        },
        minWidth: 310,
        width: 310,
        cellRenderer: DateSelectorRendererComponent,
      },
      {
        field: 'status',
        headerName: 'Status',
        minWidth: 170,
        width: 170,
        editable: false,
        // cellRenderer: OverviewRiskRendererComponent,
        cellRenderer: (params: any) => params.value === 'active' ? 'Active' : 'Cancelled',
        // cellRendererParams: {
        //   values: [
        //     {
        //       label: 'Active',
        //       value: 'Active',
        //     },
        //     {
        //       label: 'Paused',
        //       value: 'Paused',
        //     },
        //     {
        //       label: 'Cancelled',
        //       value: 'Cancelled',
        //     },
        //   ],
        // },
      },
      {
        field: 'industry',
        headerName: 'Industry',
        filter: 'agSetColumnFilter',
        minWidth: 240,
        width: 240,
        cellRenderer: OverviewRiskRendererComponent,
        cellRendererParams: {
          values: [
            {
              label: 'Legal',
              value: 'legal',
            },
            {
              label: 'Healthcare',
              value: 'healthcare',
            },
            {
              label: 'Home Services',
              value: 'home_services',
            },
            {
              label: 'Financial',
              value: 'financial',
            },
            {
              label: 'Transportation',
              value: 'transportation',
            },
            {
              label: 'Ecommerce/Catalog',
              value: 'ecommerce_catalog',
            },
            {
              label: 'Others',
              value: 'others',
            },
          ],
        },
      },
      {
        field: 'practice_area',
        headerName: 'Main PA (for Legal)',
        minWidth: 220,
        width: 220,
        filter: 'agSetColumnFilter',
        cellRenderer: OverviewRiskRendererComponent,
        cellRendererParams: {
          placeholder: 'Select',

          values: [
            {
              label: 'PI',
              value: 'PI',
            },
            {
              label: 'Criminal Defence',
              value: 'Criminal Defence',
            },
            {
              label: 'Family Law',
              value: 'Family Law',
            },
            {
              label: 'Property Damage',
              value: 'Property Damage',
            },
            {
              label: 'Immigration Law',
              value: 'Immigration Law',
            },
            {
              label: 'Estate Planning',
              value: 'Estate Planning',
            },
            {
              label: "Worker's comp",
              value: "Worker's comp",
            },
            {
              label: 'Med Mal',
              value: 'Med Mal',
            },
            {
              label: 'Business Law',
              value: 'Business Law',
            },
            {
              label: 'Other',
              value: 'Other',
            },
          ],
        },
      },
      {
        field: 'projects',
        headerName: 'Products',
        minWidth: 470,
        width: 470,
        // pinned: 'left',
        cellRenderer: OverviewProjectsRendererComponent,
        filter: 'agSetColumnFilter',
        filterValueGetter: (params: any) => {
          return params.data.projects.map((project: any) => {
            const dd = project.status === 'archived' ? ` (${project.status})` : '';
            return `${project.type}` + dd;
            // return `${project.type}`;
          });
        },
        filterParams: {
          values: (params: any) => {
            params.success([
              'PPC',
              'SEO',
              'DEV',
              'MNT',
              'SEO-INT',
              'DEV-INT',
              'OTH-INT',
              'PPC (Archived)',
              'SEO (Archived)',
              'DEV (Archived)',
              'MNT (Archived)',
              'SEO-INT (Archived)',
              'DEV-INT (Archived)',
              'OTH-INT (Archived)',
            ]);
          },
        },
      },
      {
        field: 'url',
        headerName: 'Website',
        filter: 'agTextColumnFilter',
        minWidth: 230,
        width: 230,
        editable: true,
        cellRenderer: OverviewWebsiteUrlRendererComponent,
      },
      {
        field: 'kick_off_date',
        headerName: 'Kick-off Date',
        valueFormatter: (params: any) => {
          return this.dateFormatter(params.value);
        },
        cellRenderer: DateSelectorRendererComponent,
        // editable: true,
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: this.dateCellComparator
        },
        minWidth: 170,
        width: 170,
      },
      {
        field: 'sign_in_date',
        headerName: 'Sign Date',
        valueFormatter: (params: any) => {
          return this.dateFormatter(params.value);
        },
        cellRenderer: DateSelectorRendererComponent,
        // editable: true,
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: this.dateCellComparator
        },
        minWidth: 170,
        width: 170,
      },
      {
        field: 'total_mqls.y_y.difference',
        headerName: 'Total MQLs (YoY)',
        filter: 'agNumberColumnFilter',
        cellRenderer: OverviewTotalMqlsRendererComponent,
        cellRendererParams: (params: any) => {
          return {
            values: params.data.total_mqls.y_y,
          };
        },
        minWidth: 180,
        width: 180,
      },
      {
        field: 'total_mqls.m_m.difference',
        headerName: 'Total MQLs (MoM)',
        cellRenderer: OverviewTotalMqlsRendererComponent,
        filter: 'agNumberColumnFilter',
        cellRendererParams: (params: any) => {
          return {
            values: params.data.total_mqls.m_m,
          };
        },
        minWidth: 180,
        width: 180,
      },
      {
        field: 'goals',
        headerName: 'Target MQLs',
        cellRenderer: OverviewGoalRendererComponent,
        minWidth: 280,
        width: 280,
        // pinned: 'right',
        sortable: false,
        filter: false,
      },
      {
        field: 'charts.total_traffic',
        headerName: 'Traffic Trend',
        cellRendererParams: { fill: '#94D8FF', suffix: '' },
        cellRenderer: OverviewChartRendererComponent,
        minWidth: 210,
        width: 210,
        filter: false,
      },
      {
        field: 'charts.total_unique_conversions',
        headerName: 'Unique Conversions Trend',
        cellRendererParams: { fill: '#AE9AFD', suffix: '' },
        cellRenderer: OverviewChartRendererComponent,
        minWidth: 220,
        width: 220,
        filter: false,
      },
      {
        field: 'charts.total_mqls',
        headerName: 'MQLs Trend',
        cellRendererParams: { fill: '#BCD45C', suffix: '' },
        cellRenderer: OverviewChartRendererComponent,
        minWidth: 210,
        width: 210,
        filter: false,
      },
      {
        field: 'charts.projected_roi',
        headerName: 'Projected ROI Trend',
        cellRendererParams: { fill: '#D4B25C', suffix: '%' },
        cellRenderer: OverviewChartRendererComponent,
        minWidth: 210,
        width: 210,
        filter: false,
      },
      {
        field: 'seo_budget',
        headerName: 'SEO: Budget',
        filter: 'agNumberColumnFilter',
        valueFormatter: (params: any) => {
          return this.priceFormatter(params.value);
        },
        minWidth: 150,
        width: 150,
      },
      {
        field: 'charts.seo_budget_trend',
        headerName: 'SEO: Budget Trend',
        cellRendererParams: { fill: '#5CD4BE', suffix: '', prefix: '$' },
        cellRenderer: OverviewChartRendererComponent,
        // pinned: 'right',
        minWidth: 220,
        width: 220,
        filter: false,
      },
      {
        field: 'ppc_budget',
        headerName: 'PPC: Budget',
        // pinned: 'right',
        filter: 'agNumberColumnFilter',
        valueFormatter: (params: any) => {
          return this.priceFormatter(params.value);
        },
        minWidth: 150,
        width: 150,
      },
      {
        field: 'charts.ppc_budget_trend',
        headerName: 'PPC: Budget Trend',
        cellRendererParams: { fill: '#5C85D4', suffix: '', prefix: '$' },
        cellRenderer: OverviewChartRendererComponent,
        // pinned: 'right',
        minWidth: 220,
        width: 220,
        filter: false,
      },
      {
        field: 'dev_budget',
        headerName: 'DEV: Budget',
        filter: 'agNumberColumnFilter',
        valueFormatter: (params: any) => {
          return this.priceFormatter(params.value);
        },
        minWidth: 150,
        width: 150,
        editable: true,
      },
      {
        field: 'reconciliation',
        headerName: 'DEV: Reconciliation',
        filter: 'agNumberColumnFilter',
        valueFormatter: (params: any) => {
          return this.priceFormatter(params.value);
        },
        minWidth: 170,
        width: 170,
      },
      {
        field: 'real_launch_date',
        headerName: 'Website Launch',
        valueFormatter: (params: any) => {
          return this.dateFormatter(params.value);
        },
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: this.dateCellComparator
        },
        minWidth: 170,
        width: 170,
        cellRenderer: DateSelectorRendererComponent,
      },
      {
        field: 'cms',
        headerName: 'CMS',
        filter: 'agSetColumnFilter',
        minWidth: 140,
        width: 140,
      },
      {
        field: 'dev_timeframe',
        headerName: 'DEV: Timeframe',
        cellRenderer: OverviewDevTimeframeRendererComponent,
        filter: 'agSetColumnFilter',
        filterParams: {
          valueFormatter: (params: any) => {
            // formatValue(params.value)
            console.log('pp', params.value, params.value?.status);
            return params.value?.status;
          },
        },
        valueFormatter: (params: any) => {
          return params.value?.status;
        },
        sortable: false,
        // filter: false,
        width: 180,
        minWidth: 180,
      },
      {
        field: 'forms',
        headerName: 'Problem Forms',
        cellRenderer: OverviewFormsRendererComponent,
        cellRendererParams: (params: any) => {
          return {
            row_id: params.data._id
          };
        },
        filter: 'agSetColumnFilter',
        // pinned: 'right',
        minWidth: 160,
        width: 160,
        valueFormatter: (params: any) => {
          if (params?.value?.problems && params?.value?.problems.length > 0) {
            return 'With Problems';
          }
          return 'Without Problems';
        },
      },
      // {
      //   field: 'forms',
      //   headerName: 'Problem Forms v2',
      //   cellRenderer: OverviewFormsV2RendererComponent,
      //   filter: 'agSetColumnFilter',
      //   pinned: 'right',
      //   minWidth: 160,
      //   width: 160,
      //   valueFormatter: (params: any) => {
      //     if (params?.value?.problems && params?.value?.problems.length > 0) {
      //       return 'With Problems';
      //     }
      //     return 'Without Problems';
      //   },
      // },
      {
        field: 'problem_forms_check_in_date',
        headerName: 'TEMP: Problem Forms Check-in Date',
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: this.dateCellComparator
        },
        minWidth: 310,
        width: 310,
        cellRenderer: DateSelectorRendererComponent,
      },
      {
        field: 'crux',
        headerName: 'CWV',
        cellRenderer: CruxRendererComponent,
        minWidth: 270,
        width: 270,
        filter: 'agSetColumnFilter',
        valueFormatter: (params: any) => {
          if (params.value) {
            const { lcp, fcp, cls } = params.value;
            if (lcp === 'good' && fcp === 'good' && cls === 'good') {
              return 'Good';
            } else if (lcp === 'poor' || fcp === 'poor' || cls === 'poor') {
              return 'Poor';
            } else if (lcp === 'unknown' || fcp === 'unknown' || cls === 'unknown') {
              return 'Unknown';
            }
            return 'Need Improvements';
          } else {
            return 'Error';
          }
        },
      },
      {
        field: 'ae.name',
        headerName: 'AE',
        filter: 'agSetColumnFilter',
        width: 220,
        minWidth: 220,
        cellRendererParams: {
          placeholder: 'Select AE',
          values: this.storageService.staffs?.ae
            .sort((a: any, b: any) => {
              return a.name.localeCompare(b.name);
            })
            .map((ae: any) => {
              return {
                value: ae._id,
                label: ae.name,
              };
            }),
        },
        filterParams: {
          valueFormatter: (cell: any) => {
            return this.storageService.staffs.ae.find((s: any) => s._id === cell.value)?.name;
          },
        },
        valueGetter: (params: any) => {
          return params?.data?.ae?._id;
        },
        cellRenderer: DropdownSelectorRendererComponent,
      },
      {
        field: 'am.name',
        headerName: 'AM',
        filter: 'agSetColumnFilter',
        width: 230,
        minWidth: 230,
        filterParams: {
          valueFormatter: (cell: any) => {
            return this.storageService.staffs.am.find((s: any) => s._id === cell.value)?.name;
          },
        },
        cellRendererParams: {
          placeholder: 'Select AM',
          values: this.storageService.staffs?.am
            .sort((a: any, b: any) => {
              return a.name.localeCompare(b.name);
            })
            .map((am: any) => {
              return {
                value: am._id,
                label: am.name,
              };
            }),
        },
        valueGetter: (params: any) => {
          return params?.data?.am?._id;
        },
        cellRenderer: DropdownSelectorRendererComponent,
      },
      {
        field: 'am_supervisor.name',
        headerName: 'AM supervisor',
        filter: 'agSetColumnFilter',
        width: 265,
        minWidth: 265,
        filterParams: {
          valueFormatter: (cell: any) => {
            return this.storageService.staffs.am.find((s: any) => s._id === cell.value)?.name;
          },
        },
        cellRendererParams: {
          placeholder: 'Select AM supervisor',
          values: this.storageService.staffs?.am
            .sort((a: any, b: any) => {
              return a.name.localeCompare(b.name);
            })
            .map((am: any) => {
              return {
                value: am._id,
                label: am.name,
              };
            }),
        },
        valueGetter: (params: any) => {
          return params?.data?.am_supervisor?._id;
        },
        cellRenderer: DropdownSelectorRendererComponent,
      },
      {
        field: 'pm.name',
        headerName: 'PM',
        filter: 'agSetColumnFilter',
        width: 265,
        minWidth: 265,
        cellRendererParams: {
          placeholder: 'Select PM',
          values: this.storageService.staffs?.pm
            .sort((a: any, b: any) => {
              return a.name.localeCompare(b.name);
            })
            .map((pm: any) => {
              return {
                value: pm._id,
                label: pm.name,
              };
            }),
        },
        filterParams: {
          valueFormatter: (cell: any) => {
            return this.storageService.staffs.pm.find((s: any) => s._id === cell.value)?.name;
          },
        },
        valueGetter: (params: any) => {
          return params?.data?.pm?._id;
        },
        cellRenderer: DropdownSelectorRendererComponent,
      },
      {
        field: 'seo.name',
        headerName: 'SEO',
        filter: 'agSetColumnFilter',
        width: 265,
        minWidth: 265,
        cellRendererParams: {
          placeholder: 'Select SEO',
          values: this.storageService.staffs?.seo_renderer_list // service specs, blog specs
            .filter((f: any) => f.status === 'active')
            .sort((a: any, b: any) => {
              return a.name.localeCompare(b.name);
            })
            .map((pm: any) => {
              return {
                value: pm._id,
                label: pm.name,
              };
            }),
        },
        filterParams: {
          valueFormatter: (cell: any) => {
            return this.storageService.staffs.seo_renderer_list.find((s: any) => s._id === cell.value)?.name
          },
        },
        valueGetter: (params: any) => {
          return params?.data?.seo?._id;
        },
        cellRenderer: DropdownSelectorRendererComponent,
      },
      {
        field: 'seo_supervisor.name',
        headerName: 'SEO supervisor',
        filter: 'agSetColumnFilter',
        width: 265,
        minWidth: 265,
        cellRendererParams: {
          placeholder: 'Select SEO supervisor',
          values: this.storageService.staffs.seo_supervisor_renderer_list
            .filter((f: any) => f.status === 'active')
            .sort((a: any, b: any) => {
              return a.name.localeCompare(b.name);
            })
            .map((pm: any) => {
              return {
                value: pm._id,
                label: pm.name,
              };
            }),
        },
        filterParams: {
          valueFormatter: (cell: any) => {
            return this.storageService.staffs.seo_supervisor_renderer_list.find((s: any) => s._id === cell.value)?.name;
          },
        },
        valueGetter: (params: any) => {
          return params?.data?.seo_supervisor?._id;
        },
        cellRenderer: DropdownSelectorRendererComponent,
      },
      {
        field: 'ppc.name',
        headerName: 'PPC',
        filter: 'agSetColumnFilter',
        width: 220,
        minWidth: 220,
        cellRendererParams: {
          placeholder: 'Select PPC',
          values: this.storageService.staffs?.ppc_management
            .filter((f: any) => f.status === 'active')
            .sort((a: any, b: any) => {
              return a.name.localeCompare(b.name);
            })
            .map((pm: any) => {
              return {
                value: pm._id,
                label: pm.name,
              };
            }),
        },
        filterParams: {
          valueFormatter: (cell: any) => {
            return this.storageService.staffs.ppc_management.find((s: any) => s._id === cell.value)?.name;
          },
        },
        valueGetter: (params: any) => {
          return params?.data?.ppc?._id;
        },
        cellRenderer: DropdownSelectorRendererComponent,
      },
      {
        field: 'ppc_supervisor.name',
        headerName: 'PPC supervisor',
        filter: 'agSetColumnFilter',
        width: 220,
        minWidth: 220,
        cellRendererParams: {
          placeholder: 'Select PPC supervisor',
          values: this.storageService.staffs?.ppc_management
            .filter((f: any) => f.status === 'active')
            .sort((a: any, b: any) => {
              return a.name.localeCompare(b.name);
            })
            .map((pm: any) => {
              return {
                value: pm._id,
                label: pm.name,
              };
            }),
        },
        filterParams: {
          valueFormatter: (cell: any) => {
            return this.storageService.staffs.ppc_management.find((s: any) => s._id === cell.value)?.name;
          },
        },
        valueGetter: (params: any) => {
          return params?.data?.ppc_supervisor?._id;
        },
        cellRenderer: DropdownSelectorRendererComponent,
      },
      {
        field: 'dev.name',
        headerName: 'DEV',
        filter: 'agSetColumnFilter',
        width: 220,
        minWidth: 220,
        cellRendererParams: {
          placeholder: 'Select DEV',
          values: this.storageService.staffs?.development
            .filter((f: any) => f.status === 'active')
            .sort((a: any, b: any) => {
              return a.name.localeCompare(b.name);
            })
            .map((pm: any) => {
              return {
                value: pm._id,
                label: pm.name,
              };
            }),
        },
        filterParams: {
          valueFormatter: (cell: any) => {
            return this.storageService.staffs.development.find((s: any) => s._id === cell.value)?.name;
          },
        },
        valueGetter: (params: any) => {
          return params?.data?.dev?._id;
        },
        cellRenderer: DropdownSelectorRendererComponent,
      },
      {
        field: 'des.name',
        headerName: 'DES',
        filter: 'agSetColumnFilter',
        width: 220,
        minWidth: 220,
        cellRendererParams: {
          placeholder: 'Select DES',
          values: this.storageService.staffs?.design
            .filter((f: any) => f.status === 'active')
            .sort((a: any, b: any) => {
              return a.name.localeCompare(b.name);
            })
            .map((pm: any) => {
              return {
                value: pm._id,
                label: pm.name,
              };
            }),
        },
        filterParams: {
          valueFormatter: (cell: any) => {
            return this.storageService.staffs.design.find((s: any) => s._id === cell.value)?.name;
          },
        },
        valueGetter: (params: any) => {
          return params?.data?.des?._id;
        },
        cellRenderer: DropdownSelectorRendererComponent,
      },
      {
        field: 'reporting_service',
        headerName: 'Reporting Service',
        filter: 'agSetColumnFilter',
        minWidth: 230,
        width: 230,
        // pinned: 'right',
        cellRenderer: OverviewReportingServiceRendererComponent,
      },
      {
        field: 'brand',
        headerName: 'Brand',
        minWidth: 300,
        width: 300,
        cellRenderer: OverviewBrandRendererComponent,
        cellRendererParams: {
          values: [
            {
              label: 'Comrade Digital Marketing',
              value: 'Comrade Digital Marketing',
              data: {
                link: 'https://comradeweb.com',
                label: 'Comrade Digital Marketing',
                from: 'info@comradeweb.com',
              },
            },
            {
              label: 'Grow Law Firm',
              value: 'Grow Law Firm',
              data: {
                link: 'https://growlawfirm.com',
                label: 'Grow Law Firm',
                from: 'info@growlawfirm.com',
              },
            },
          ],
        },
      },
      {
        field: 'client_access',
        headerName: 'Client Access',
        minWidth: 290,
        width: 290,
        cellRenderer: OverviewClientAccessRendererComponent,
        filter: false,
        sortable: false,
      },
      {
        field: 'risk_factor',
        headerName: 'Risk Factor',
        minWidth: 280,
        width: 280,
        cellRenderer: OverviewRiskRendererComponent,
        cellRendererParams: {
          values: [
            {
              label: 'Low',
              value: 'Low',
              fill: '#BCD45C',
            },
            {
              label: 'Medium',
              value: 'Medium',
              fill: '#D4B25C',
            },
            {
              label: 'Medium - New Project',
              value: 'Medium - New Project',
              fill: '#E2E3E5',
            },
            {
              label: 'High',
              value: 'High',
              fill: '#D45C5C',
            },
            {
              label: 'Not needed',
              value: 'Not needed',
              fill: '#D45C5C',
            },
          ],
        },
      },
      {
        field: 'risk_reason',
        headerName: 'Risk Reason',
        minWidth: 345,
        width: 345,
        cellRenderer: OverviewRiskRendererComponent,
        cellRendererParams: {
          values: [
            {
              label: 'Underperformance',
              value: 'Underperformance',
              fill: '#D45C5C',
            },
            {
              label: 'Indifference',
              value: 'Indifference',
              fill: '#D4B25C',
            },
            {
              label: 'No Innovation',
              value: 'No Innovation',
              fill: '#D45C5C',
            },
            {
              label: 'Industry Decline',
              value: 'Industry Decline',
              fill: '#E2E3E5',
            },
            {
              label: 'Internal Changes',
              value: 'Internal Changes',
              fill: '#E2E3E5',
            },
            {
              label: 'Missed Expectations',
              value: 'Missed Expectations',
              fill: '#D4B25C',
            },
            {
              label: 'Budget',
              value: 'Budget',
              fill: '#E2E3E5',
            },
            {
              label: 'Lack of Alignment on Goals',
              value: 'Lack of Alignment on Goals',
              fill: '#D45C5C',
            },
            {
              label: 'Budgeting for Growth vs Survival',
              value: 'Budgeting for Growth vs Survival',
              fill: '#E2E3E5',
            },
            {
              label: 'Compounding Mistakes',
              value: 'Compounding Mistakes',
              fill: '#D45C5C',
            },
            {
              label: 'Lack of Specialization',
              value: 'Lack of Specialization',
              fill: '#E2E3E5',
            },
            {
              label: 'Alternative Lead Gen Investment',
              value: 'Alternative Lead Gen Investment',
              fill: '#fff',
            },
            {
              label: 'Product Quality SEO',
              value: 'Product Quality SEO',
              fill: '#E2E3E5',
            },
            {
              label: 'Corporation',
              value: 'Corporation',
              fill: '#E2E3E5',
            },
            {
              label: "Client's personality",
              value: "Client's personality",
              fill: '#D45C5C',
            },
            {
              label: 'Lack of cooperation',
              value: 'Lack of cooperation',
              fill: '#D45C5C',
            },
            {
              label: 'Product Quality - PPC',
              value: 'Product Quality - PPC',
              fill: '#E2E3E5',
            },
          ],
        },
      },
      {
        field: 'icp',
        headerName: 'ICP',
        minWidth: 180,
        width: 180,
        cellRenderer: OverviewRiskRendererComponent,
        cellRendererParams: {
          values: [
            {
              label: 'YES',
              value: 'Yes',
              fill: '#BCD45C',
            },
            {
              label: 'OK',
              value: 'Ok',
              fill: '#D4B25C',
            },
            {
              label: 'NO',
              value: 'No',
              fill: '#D45C5C',
            },
          ],
        },
        // // editable: true,
        // filter: 'agDateColumnFilter',
      },
      {
        field: 'cancellation_date',
        headerName: 'Cancellation Date',
        valueFormatter: (params: any) => {
          return this.dateFormatter(params.value);
        },
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: this.dateCellComparator
        },
        minWidth: 170,
        width: 170,
        cellRenderer: DateSelectorRendererComponent,
      },
      {
        field: 'churn_reason',
        headerName: 'Churn Reason',
        minWidth: 170,
        width: 170,
        editable: (params: any) => {
          if (params.data.client_name !== 'Temporary Client') {
            return true;
          } else {
            return false
          }
        },
        cellEditor: 'agLargeTextCellEditor',
      },
      {
        field: 'crm',
        headerName: 'CRM Name',
        minWidth: 190,
        width: 190,
        cellRenderer: OverviewRiskRendererComponent,
        cellRendererParams: {
          placeholder: 'Select',
          values: [
            {
              label: 'Zoho',
              value: 'Zoho',
            },
            {
              label: 'Hubspot',
              value: 'Hubspot',
            },
            {
              label: 'Salesforce',
              value: 'Salesforce',
            },
            {
              label: 'GoHighLevel',
              value: 'GoHighLevel',
            },
            {
              label: 'Clio',
              value: 'Clio',
            },
            {
              label: 'Cloudlex',
              value: 'Cloudlex',
            },
            {
              label: 'Plutio',
              value: 'Plutio',
            },
            {
              label: 'Dutchie',
              value: 'Dutchie',
            },
            {
              label: 'SmartMoving',
              value: 'SmartMoving',
            },
            {
              label: 'Lawmatics',
              value: 'Lawmatics',
            },
            {
              label: 'GetJobber',
              value: 'GetJobber',
            },
            {
              label: 'GoBoomerang',
              value: 'GoBoomerang',
            },
            {
              label: 'JobProgress',
              value: 'JobProgress',
            },
            {
              label: 'PipeDrive',
              value: 'PipeDrive',
            },
            {
              label: 'LeadSimple',
              value: 'LeadSimple',
            },
            {
              label: 'ServiceTitan',
              value: 'ServiceTitan',
            },
            {
              label: 'ClientWhys',
              value: 'ClientWhys',
            },
            {
              label: 'Other',
              value: 'Other',
            },
          ],
        },
      },
      {
        field: 'churn_months',
        headerName: 'Churn Age, months',
        filter: 'agNumberColumnFilter',
        valueFormatter: (params: any) => {
          if (params.value) {
            return params.value.toLocaleString('en', { maximumFractionDigits: 2 });
          } else {
            return '';
          }
        },
        minWidth: 160,
        width: 160,
        editable: false,
      },
      {
        field: 'active_months',
        headerName: 'Active Age, months',
        filter: 'agNumberColumnFilter',
        valueFormatter: (params: any) => {
          if (params.value) {
            return params.value.toLocaleString('en', { maximumFractionDigits: 2 });
          } else {
            return '';
          }
        },
        minWidth: 160,
        width: 160,
        editable: false,
      },
      // {
      //   field: 'google_ads_conversion_action1',
      //   headerName: 'PPC GCLID Sync & Setup',
      //   filter: false,
      //   pinned: 'right',
      //   editable: false,
      //   cellRenderer: OverviewGoogleAdsConversionActionRendererComponent,
      //   valueFormatter: (params: any) => {
      //     return params.value;
      //   },
      //   minWidth: 670,
      //   width: 670,
      //   // maxWidth: 200
      // },
      {
        field: 'google_ads_conversion_action',
        headerName: 'PPC GCLID Sync & Setup',
        filter: false,
        // pinned: 'right',
        // sort: 'desc',
        editable: false,
        cellRenderer: OverviewGoogleAdsConversionActionV2RendererComponent,
        valueFormatter: (params: any) => {
          return params.value;
        },
        comparator: (a: any, b: any) => {
          const aValue = a?.cp_auto_upload_clicks?.statistic?.synced_contacts || 0;
          const bValue = b?.cp_auto_upload_clicks?.statistic?.synced_contacts || 0;

          if (aValue === bValue) return 0;
          if (aValue === undefined || bValue === undefined) return -1;

          return (aValue > bValue) ? 1 : -1;
        },
        minWidth: 630,
        width: 630,
        maxWidth: 800
      },
      {
        field: 'approximate_page_count',
        headerName: 'Approximate number of pages',
        filter: 'agNumberColumnFilter',
        valueFormatter: (params: any) => {
          if (params.value) {
            return params.value.toLocaleString('en', { maximumFractionDigits: 2 });
          } else {
            return '';
          }
        },
        minWidth: 270,
        width: 270,
        editable: true,
      },
      {
        field: 'deals_stats',
        headerName: 'Deals',
        filter: false,
        // pinned: 'right',
        editable: false,
        cellRenderer: OverviewDealsStatsRendererComponent,
        minWidth: 310,
        width: 310,
      },
      {
        field: 'average_check',
        headerName: 'Average Check',
        // pinned: 'right',
        filter: 'agNumberColumnFilter',
        valueFormatter: (params: any) => {
          return this.priceFormatter(params.value);
        },
        minWidth: 180,
        width: 180,
      },
      {
        field: 'closing_rate_mql_client',
        headerName: 'Closing rate MQL -> CLIENT',
        // pinned: 'right',
        filter: 'agNumberColumnFilter',
        valueFormatter: (params: any) => {
          if (params.value) {
            return params.value + '%';
          } else {
            return '';
          }
        },
        minWidth: 180,
        width: 180,
      },
    ];

    if (this.gridColumnPresetSelected === 'full_view') {
      this.makeGridColumns('full_view');
    } else if (this.gridColumnPresetSelected === 'churn_risk_view') {
      this.makeGridColumns('churn_risk_view');
    }  else if (this.gridColumnPresetSelected === 'setup_view') {
      this.makeGridColumns('setup_view');
    }
    this.gridReady = true;
  }

  makeGridColumns(presetName: string) {
    const columns = this.gridColumnPresetsList.find((p: any) => p.value === presetName).columns;
    const result = [];

    for (const column of columns) {
      const columnSettings = this.presetColDefs.find((colDef: any)=> colDef.field === column);
      result.push(columnSettings);
    }

    this.colDefs = result;
  }

  getFormatedDateTime(date: any) {
    var dateFormated = new Date();
    return dateFormated.toLocaleDateString();
  };

  dateTimeFormat = (params: ValueFormatterParams) => {
    const dateNum = params.value;
    if (dateNum) {
      // keep day/month order consistent with locale, and pad with 0 where needed
      const dateStr = this.getFormatedDateTime(dateNum);
      const timeStr = formatDate(dateNum, 'hh:mm a', this.locale);
      return `${dateStr}, ${timeStr}`;
    }
    return '';
  };

  testROI_report(data: any) {
    const legalsFirm = data.filter((d: any) => d.industry === 'legal').slice(0, 3);
    console.log('✳️✳️✳️✳️✳️✳️✳️✳️✳️✳️✳️✳️✳️✳️✳️✳️✳️');

    const makeArrayData = [];
    for (const row of legalsFirm) {
      makeArrayData.push({
        _id: row._id,
        abbr: row.abbr,
        client_name: row.client_name,
        projected_roi: row.charts.projected_roi,
      });

      this.apiService.getKpiData(row._id).subscribe((resp: any) => {
        console.log('kpi', resp);
      })
    }
    console.log('::', makeArrayData);


    console.log('✳️✳️✳️✳️✳️✳️✳️✳️✳️✳️✳️✳️✳️✳️✳️✳️✳️');
  }

  async onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.storageService.overviewGridApi = params.api;

    this.gridApi?.setGridOption('loading', true);

    const columnState: any = localStorage.getItem(`overview-${this.gridColumnPresetSelected}-columnState`);

    if (columnState) {
      this.gridApi.applyColumnState({
        state: JSON.parse(columnState),
        applyOrder: true,
      });
    }

    this.rowData = await this.apiService.getOverview();

    // setTimeout(() => {
    //   const rowNode = this.storageService.overviewGridApi?.getRowNode('679cbdc7e17e7ccc9ad1c5d8')!;
    //   console.log('rowNode', rowNode);
    //   rowNode.setData({ ...rowNode.data, ...{ row_is_blocked: true } });
    // }, 1000);

    // console.log('this.rowData', this.rowData);

    // this.testROI_report(this.rowData);

    const searchFilter = localStorage.getItem('overview-quickFilterText');

    if (searchFilter) {
      this.gridApi?.setGridOption('quickFilterText', searchFilter);
      this.searchText = searchFilter;
    }
    const savedFilterModel = localStorage.getItem(`overview-${this.gridColumnPresetSelected}-filter-model`);

    setTimeout(() => {
      if (savedFilterModel) {
        try {
          this.gridApi?.setFilterModel(JSON.parse(savedFilterModel));
        } catch (e) {
          console.error('Error loading filter model', e, savedFilterModel);
        }
      } else {
        if (this.gridColumnPresetSelected !== 'setup_view') {
          this.gridApi
            ?.setColumnFilterModel('projects', {
              values: ['DEV', 'PPC', 'SEO'],
            })
            .then(() => {
              this.gridApi?.onFilterChanged();
            });

          const user_id = localStorage.getItem('user_id');

          if (user_id) {
            const am: any = this.rowData.find((x: any) => x.am?._id === user_id);
            const pm: any = this.rowData.find((x: any) => x.pm?._id === user_id);

            if (am) {
              this.gridApi
                ?.setColumnFilterModel('am.name', {
                  // values: [am.am.name],
                  values: [am.am._id],
                })
                .then(() => {
                  this.gridApi?.onFilterChanged();
                });
            } else if (pm) {
              this.gridApi
                ?.setColumnFilterModel('pm.name', {
                  // values: [pm.pm.name],
                  values: [pm.pm._id],
                  // values: ['Irena Mogileva'],
                })
                .then(() => {
                  this.gridApi?.onFilterChanged();
                });
            }
          }
        } else {
          this.gridApi
            ?.setColumnFilterModel('client_name', {
              values: ['', 'Temporary Client'],
            })
            .then(() => {
              this.gridApi?.onFilterChanged();
            });
        }
      }

      this.stringResultChange();

      setTimeout(() => {
        this.gridApi?.setGridOption('loading', false);
      }, 100);
    }, 100);
  }

  onGridChangePreset(event: any) {
    localStorage.setItem('overview-grid-active-preset', event.value);
    this.gridColumnPresetSelected = event.value;

    this.gridReady = false;
    if (event.value === 'full_view') {
      this.colDefs = [];
      setTimeout(() => {
        this.makeGridColumns('full_view');
        this.gridReady = true;
      }, 50);
    } else if (event.value === 'churn_risk_view') {
      this.colDefs = [];
      setTimeout(() => {
        this.makeGridColumns('churn_risk_view');
        this.gridReady = true;
      }, 50);
    } else if (event.value === 'setup_view') {
      this.colDefs = [];
      setTimeout(() => {
        this.makeGridColumns('setup_view');
        this.gridReady = true;
      }, 50);
    }

    const columnState: any = localStorage.getItem(`overview-${this.gridColumnPresetSelected}-columnState`);

    if (columnState) {
      setTimeout(() => {
        this.gridApi?.applyColumnState({
          state: JSON.parse(columnState),
          applyOrder: true,
        });
      }, 300);
    }
    const savedFilterModel = localStorage.getItem(`overview-${this.gridColumnPresetSelected}-filter-model`);

    setTimeout(() => {
      if (savedFilterModel) {
        try {
          this.gridApi?.setFilterModel(JSON.parse(savedFilterModel));
        } catch (e) {
          console.error('Error loading filter model', e, savedFilterModel);
        }

        setTimeout(() => {
          this.checkColumnStateChanged();
        }, 150);
      } else {
        if (event.value !== 'setup_view') {
          this.gridApi
            ?.setColumnFilterModel('projects', {
              values: ['DEV', 'PPC', 'SEO'],
            })
            .then(() => {
              this.gridApi?.onFilterChanged();
            });

          const user_id = localStorage.getItem('user_id');
          if (user_id) {
            const am: any = this.rowData.find((x: any) => x.am?._id === user_id);
            const pm: any = this.rowData.find((x: any) => x.pm?._id === user_id);

            if (am) {
              this.gridApi
                ?.setColumnFilterModel('am.name', {
                  values: [am.am._id],
                })
                .then(() => {
                  this.gridApi?.onFilterChanged();
                });
            } else if (pm) {
              this.gridApi
                ?.setColumnFilterModel('pm.name', {
                  values: [pm.pm._id],
                })
                .then(() => {
                  this.gridApi?.onFilterChanged();
                });
            }
          }
          setTimeout(() => {
            this.checkColumnStateChanged();
          }, 100);
        } else {
          this.gridApi
            ?.setColumnFilterModel('client_name', {
              values: ['', 'Temporary Client'],
            })
            .then(() => {
              this.gridApi?.onFilterChanged();
            });
        }
      }
    }, 150);
  }

  stringResultChange() {
    const rows: any = this.gridApi?.getDisplayedRowCount();
    this.filterInfoString =
      rows > 0 ? `${rows.toLocaleString('en', { maximumFractionDigits: 2 })} results found` : `0 results found`;
  }

  public removeFilter(filter: any) {
    const currentFilterModel: any = this.gridApi?.getFilterModel();
    delete currentFilterModel[filter.field];
    this.activeFilters = this.activeFilters.filter((x: any) => x.name !== filter.name);

    if (this.activeFilters.length === 0) {
      this.showSearchEverywhereMessage = false;
    }

    this.gridApi?.setFilterModel(currentFilterModel);
  }

  onSearchEverywhere() {
    this.showSearchEverywhereMessage = false;
    this.gridApi?.setFilterModel({});
    this.activeFilters = [];
  }

  filterParams: IDateFilterParams = {
    comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
      // const dateAsString = cellValue;
      const dateAsString = this.dateFormatter(cellValue);
      console.log('cellValue', dateAsString);
      if (dateAsString == null) return -1;
      const dateParts = dateAsString.split("/");
      const cellDate = new Date(
        Number(dateParts[2]),
        Number(dateParts[1]) - 1,
        Number(dateParts[0]),
      );
      console.log('cellDate', cellDate);
      if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
        return 0;
      }
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      }
      if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
      return 0;
    },
    browserDatePicker: true,
    // minValidYear: 2000,
    // maxValidYear: 2021,
    inRangeFloatingFilterDateFormat: "MM/DD/YYYY",
    // inRangeFloatingFilterDateFormat: "Do MMM YYYY",
  };

  test() {
    this.gridApi?.setFilterModel({
      "problem_forms_check_in_date": {
        "dateFrom": "2025-03-09T00:00:00.000Z",
        // "dateTo": "2025-03-09T00:00:00.000Z",
        "dateTo": null,
        "filterType": "date",
        "type": "equals"
      }
    });
  }

  checkColumnStateChanged(presetActive: boolean = false) {
    const currentState = this.gridApi?.getColumnState();
    const initialState = this.colDefs.map((col: any) => {
      return {
        colId: col.field,
        width: col.width || col.minWidth || col.maxWidth,
        hide: !!col.hide,
        pinned: col.pinned || null,
        sort: col.sort || null,
        sortIndex: null,
        aggFunc: null,
        rowGroup: false,
        rowGroupIndex: null,
        pivot: false,
        pivotIndex: null,
        flex: null,
      };
    });

    if (JSON.stringify(currentState) === JSON.stringify(initialState)) {
      this.disabledResetColumnButton = true;
    } else {
      this.disabledResetColumnButton = false;
    }
  }

  resetColumnState() {
    this.gridApi?.resetColumnState();
    this.gridApi?.applyColumnState({
      state: this.colDefs.map((col: any) => {
        return {
          colId: col.field,
          width: col.width || col.minWidth || col.maxWidth,
        };
      }),
      applyOrder: true,
    });
    localStorage.setItem(`overview-${this.gridColumnPresetSelected}-columnState`, JSON.stringify({}));
    this.checkColumnStateChanged();
  }

  resetFilters() {
    if (this.disableResetFilters === false) {
      this.searchText = '';
      this.gridApi?.setGridOption('quickFilterText', '');
      this.showSearchEverywhereMessage = false;
      this.gridApi?.setFilterModel({});

      if (['full_view', 'churn_risk_view'].includes(this.gridColumnPresetSelected)) {
        this.gridApi
          ?.setColumnFilterModel('projects', {
            values: ['DEV', 'PPC', 'SEO'],
          })
          .then(() => {
            this.gridApi?.onFilterChanged();
          });
      } else if (this.gridColumnPresetSelected === 'setup_view') {
        this.gridApi
          ?.setColumnFilterModel('client_name', {
            values: ['', 'Temporary Client'],
          })
          .then(() => {
            this.gridApi?.onFilterChanged();
          });
      }

      localStorage.setItem('overview-quickFilterText', '');
    }
  }

  public clearSearchValue() {
    this.searchText = '';
    this.showSearchEverywhereMessage = false;
    this.gridApi?.setGridOption('quickFilterText', '');
    localStorage.setItem('overview-quickFilterText', '');
  }

  private makeFilters() {
    this.activeFilters = [];
    const filters = this.gridApi?.getFilterModel();

    if (filters) {
      for (const [key, value] of Object.entries(filters)) {
        if (value.values) {
          let fName: any = this.colDefs.find((c) => c.field === key)?.headerName;
          let filterName = fName;

          if (key === 'am.name') {
            const vv = value.values.map((v: any) => this.storageService.staffs.am.find((s: any) => s._id === v)?.name);
            this.activeFilters.push({
              field: key,
              name: filterName,
              value: vv.join(', '),
            });
          } else if (key === 'pm.name') {
            // filterName = 'PM';
            const vv = value.values.map((v: any) => this.storageService.staffs.pm.find((s: any) => s._id === v)?.name);
            this.activeFilters.push({
              field: key,
              name: filterName,
              value: vv.join(', '),
            });
          } else if (key === 'seo.name') {
            const vv = value.values.map((v: any) => this.storageService.staffs.seo_renderer_list.find((s: any) => s._id === v)?.name);
            this.activeFilters.push({
              field: key,
              name: filterName,
              value: vv.join(', '),
            });
          } else if (key === 'seo_supervisor.name') {
            const vv = value.values.map((v: any) => this.storageService.staffs.seo_supervisor_renderer_list.find((s: any) => s._id === v)?.name);
            this.activeFilters.push({
              field: key,
              name: filterName,
              value: vv.join(', '),
            });
          } else if (key === 'am_supervisor.name') {
            const vv = value.values.map((v: any) => this.storageService.staffs.am_supervisor.find((s: any) => s._id === v)?.name);
            this.activeFilters.push({
              field: key,
              name: filterName,
              value: vv.join(', '),
            });
          } else if (key === 'ae.name') {
            const vv = value.values.map((v: any) => this.storageService.staffs.ae.find((s: any) => s._id === v)?.name);
            this.activeFilters.push({
              field: key,
              name: filterName,
              value: vv.join(', '),
            });
          } else if (key === 'ppc.name') {
            const vv = value.values.map((v: any) => this.storageService.staffs.ppc_management.find((s: any) => s._id === v)?.name);
            this.activeFilters.push({
              field: key,
              name: filterName,
              value: vv.join(', '),
            });
          } else if (key === 'ppc_supervisor.name') {
            const vv = value.values.map((v: any) => this.storageService.staffs.ppc_management.find((s: any) => s._id === v)?.name);
            this.activeFilters.push({
              field: key,
              name: filterName,
              value: vv.join(', '),
            });
          } else if (key === 'dev.name') {
            const vv = value.values.map((v: any) => this.storageService.staffs.development.find((s: any) => s._id === v)?.name);
            this.activeFilters.push({
              field: key,
              name: filterName,
              value: vv.join(', '),
            });
          } else if (key === 'des.name') {
            const vv = value.values.map((v: any) => this.storageService.staffs.design.find((s: any) => s._id === v)?.name);
            this.activeFilters.push({
              field: key,
              name: filterName,
              value: vv.join(', '),
            });
          } else {
            this.activeFilters.push({
              field: key,
              name: filterName,
              value: value.values
                .filter((x: any) => x)
                .map((x: any) => x.replace(/_/g, ' '))
                .join(', '),
            });
          }
        } else if (value.filterType === 'date') {
          let fName: any = this.colDefs.find((c) => c.field === key)?.headerName;
          let condition = value.type;
          if (condition === 'notEqual') {
            condition = 'Does not equal';
          } else if (condition === 'lessThan') {
            condition = 'Before';
          } else if (condition === 'greaterThan') {
            condition = 'After';
          } else if (condition === 'inRange') {
            condition = 'Between';
          } else if (condition === 'notBlank') {
            condition = 'Not blank';
          }

          if (!['blank', 'notBlank'].includes(value.type)) {
            let dateValue = new Date(value.dateFrom).toLocaleDateString('en-US');
            if (value.dateTo) {
              dateValue += ` - ${new Date(value.dateTo).toLocaleDateString('en-US')}`;
            }

            this.activeFilters.push({
              field: key,
              name: fName + ` (${condition})` + `: ${dateValue}`,
              value: value.filter,
            });
          } else {
            this.activeFilters.push({
              field: key,
              name: fName + ` (${condition})`,
              value: value.filter,
            });
          }

        } else {
          let condition = value.type;
          if (condition === 'notContains') {
            condition = 'Does not contain';
          } else if (condition === 'startsWith') {
            condition = 'Begins with';
          } else if (condition === 'endsWith') {
            condition = 'Ends with';
          } else if (condition === 'notEqual') {
            condition = 'Does not equal';
          } else if (condition === 'greaterThan') {
            condition = 'Greater than';
          } else if (condition === 'greaterThanOrEqual') {
            condition = 'Greater than or equal to';
          } else if (condition === 'lessThan') {
            condition = 'Less than';
          } else if (condition === 'lessThanOrEqual') {
            condition = 'Less than or equal to';
          } else if (condition === 'inRange') {
            condition = 'Between';
          } else if (condition === 'notBlank') {
            condition = 'Not blank';
          }

          if (!['blank', 'notBlank'].includes(value.type)) {
            let numberValue = value.filter.toLocaleString('en', { maximumFractionDigits: 2 });
            if (value.filterTo) {
              numberValue += ` - ${value.filterTo.toLocaleString('en', { maximumFractionDigits: 2 })}`;
            }
            let fName: any = this.colDefs.find((c) => c.field === key)?.headerName;
            this.activeFilters.push({
              field: key,
              name: fName + ` (${condition})`,
              value: value.filterType === 'number' ? numberValue : String(value.filter),
            });
          } else {
            let fName: any = this.colDefs.find((c) => c.field === key)?.headerName;
            this.activeFilters.push({
              field: key,
              name: fName + ` (${condition})`,
              value: '',
            });
          }
        }
      }
    }

    if (this.activeFilters.length > 0 && this.searchText.length > 0) {
      this.showSearchEverywhereMessage = true;
    }

    if (JSON.stringify(this.activeFilters) === '[{"field":"projects","name":"Products","value":"DEV, PPC, SEO"}]') {
      this.disableResetFilters = true;
    } else if (JSON.stringify(this.activeFilters) === '[{"field":"client_name","name":"Client Name","value":"Temporary Client"}]') {
      this.disableResetFilters = true;
    } else {
      this.disableResetFilters = false;
    }
  }

  updateValue(params: any) {
    const data = {
      platform_id: params.data.platform_id,
      field: params.colDef.field,
      value: params.value,
    };

    const rowNode = params.api.getRowNode(params.data._id)!;
    rowNode.setData({ ...params.data, ...{ [params.colDef.field]: params.value, row_is_blocked: true } });

    this.apiService.overviewUpdateValue(data).subscribe({
      next: (res: any) => {
        params.api.applyTransaction({
          update: [res],
        });
        this.messageService.add({
          severity: 'success',
          summary: 'Overview Update',
          detail: 'Data Successfully Updated',
        });

        const rowNode = params.api.getRowNode(params.data._id)!;
        rowNode.setData({ ...res, ...{ reporting_service: params.value, row_is_blocked: false } });
      },
      error: (err) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Overview Update Error!',
          detail: err.message,
        });

        const rowNode = params.api.getRowNode(params.data._id)!;
        rowNode.setData({ ...params.data, ...{ [params.colDef.field]: params.oldValue, row_is_blocked: false } });
      },
    });
  }

  onCellEditingStopped(event: any) {
    if (event.valueChanged) {
      this.confirmationService.confirm({
        // target: event.target as EventTarget,
        message: 'This action will update both the default table and analytics. Do you want to proceed?',
        header: 'Confirmation',
        closable: false,
        closeOnEscape: false,
        // icon: 'pi pi-exclamation-triangle',
        rejectButtonProps: {
          label: 'Cancel',
          severity: 'secondary',
          outlined: true,
        },
        acceptButtonProps: {
          label: 'Save',
        },
        accept: () => {
          this.updateValue(event);
          // this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted' });
        },
        reject: () => {
          const rowNode = event.api.getRowNode(event.data._id)!;
          rowNode.setData({ ...event.data, ...{ [event.colDef.field]: event.oldValue, row_is_blocked: false } });
          // this.cellValue = this.initialValue;
          // this.updateSelectValues();
        },
      });
    }
  }

  columnEverythingChanged(event: any) {
    setTimeout(() => {
      const savedState = this.gridApi?.getColumnState();
      localStorage.setItem(`overview-${this.gridColumnPresetSelected}-columnState`, JSON.stringify(savedState));
      this.checkColumnStateChanged();
    }, 400);
  }

  dateCellComparator(filterLocalDateAtMidnight: Date, cellValue: string) {
    const dateAsString = cellValue;
    if (dateAsString == null) return -1;
    const dateParts = dateAsString.split('T')[0].split("-");
    const cellDate = new Date(Number(dateParts[0]), Number(dateParts[1]) - 1, Number(dateParts[2]));
    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
    return 0;
  }

  filterChanged(event: any) {
    const filterModel = this.gridApi?.getFilterModel();
    if (JSON.stringify(filterModel) === '{}') {
      localStorage.removeItem(`overview-${this.gridColumnPresetSelected}-filter-model`)
    } else {
      localStorage.setItem(`overview-${this.gridColumnPresetSelected}-filter-model`, JSON.stringify(filterModel));
    }

    this.makeFilters();
    this.stringResultChange();
  }

  searchHandler(event: any) {
    clearTimeout(this.searchHandlerTimeout);
    if (event.target.value === '') {
      this.showSearchEverywhereMessage = false;
    } else if (this.activeFilters.length > 0) {
      this.showSearchEverywhereMessage = true;
    } else {
      this.showSearchEverywhereMessage = false;
    }
    this.searchHandlerTimeout = setTimeout(() => {
      this.gridApi?.setGridOption('quickFilterText', event.target.value);
      localStorage.setItem('overview-quickFilterText', event.target.value);
    }, 200);
  }

  protected readonly Number = Number;
}
