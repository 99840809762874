import { Component, inject } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { ChartModule } from 'primeng/chart';
import { AgChartOptions } from 'ag-charts-community';
import { AgCharts } from 'ag-charts-angular';
import { Skeleton } from 'primeng/skeleton';
import { AgCartesianSeriesTooltipRendererParams } from 'ag-charts-enterprise';
import { StorageService } from '../../../../services/storage.service';

@Component({
  selector: 'app-overview-chart-renderer',
  imports: [ChartModule, AgCharts, Skeleton],
  templateUrl: './overview-chart-renderer.component.html',
  styleUrl: './overview-chart-renderer.component.scss',
})
export class OverviewChartRendererComponent {
  private storageService: StorageService = inject(StorageService);
  public chartOptions: AgChartOptions | undefined;
  private fill: string = '';
  private suffix: string = '';
  private prefix: string = '';

  basicData: any;

  loading: boolean = true;
  noData: boolean = false;
  basicOptions: any;
  protected params: any = {};
  protected values: any = [];

  constructor() {}
  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.values = this.params.value;
    this.fill = this.params.fill;
    this.suffix = this.params.suffix || '';
    this.prefix = this.params.prefix || '';

    this.chartOptions = {
      // Data: Data to be displayed in the chart
      animation: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      padding: {
        right: 0,
        left: 0,
        top: 5,
        bottom: 0,
      },
      data: [],
      axes: [
        {
          type: 'number',
          position: 'left',
          label: {
            enabled: false,
          },
          // thickness: 0,
          crosshair: {
            enabled: false,
          },
        },
        {
          type: 'category',
          position: 'bottom',
          paddingInner: 0.1,
          line: {
            enabled: false,
          },
          gridLine: {
            // enabled: false,
            width: 22,
            style: [{
              stroke: '#fff',
            }]
          },
          // thickness: 0,
          // paddingOuter: 0,
          label: {
            enabled: false,
          },
          crosshair: {
            enabled: false,
          },
        },
      ],
      // Series: Defines which chart type and data to use
      series: [
        {
          type: 'bar',
          xKey: 'period',
          yKey: 'value',
          cornerRadius: 6,
          fill: this.fill,
          label: {
            enabled: false,
          },
          tooltip: {
            // position: {
            //   xOffset: 150,
            //   yOffset: 1
            // },
            renderer: (params: AgCartesianSeriesTooltipRendererParams) => {
              const { period, value } = params.datum;
              const mon = this.storageService.months[period.split('_')[0]];
              const year = period.split('_')[1];
              return `<div style="padding: 4px;">${mon} ${year}: <b>${this.prefix}${value.toLocaleString('en', { maximumFractionDigits: 2 })}${this.suffix}</b></div>`;
            },
            interaction: {
              enabled: false,
            },
          },
        },
      ],
    };

    // console.log('--', this.values?.filter((v: any) => v.value !== null));
    // this.chartOptions.data = this.values;

    if (this.values && this.values[0]?.value === null) {
      this.chartOptions.data = this.values?.map((v: any) => {
        return {
          period: v.period,
          value: v.value || 0,
        }
      });
    } else {
      this.chartOptions.data = this.values;
    }

    if (this.values === null) {
      this.noData = true;
    }

    this.refresh(params);
  }
  refresh(params: any): boolean {
    // this.values = params?.values;
    // console.log('values ---', this.values);
    // this.chartOptions.data = this.values;

    return true;
  }
  ngOnInit() {
    if (this.params.data.row_is_blocked === true || this.params.data.row_is_blocked === false) {
      this.loading = false;
    } else {
      setTimeout(() => {
        this.loading = false;
      }, 500 * Math.random());
    }
  }

}
