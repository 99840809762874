import { Component } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputText } from 'primeng/inputtext';
import { FormsModule } from '@angular/forms';
import { ButtonComponent } from '../../button/button.component';

@Component({
  selector: 'app-overview-forms-update-dialog',
  imports: [InputText, FormsModule, ButtonComponent],
  templateUrl: './overview-forms-update-dialog.component.html',
  styleUrl: './overview-forms-update-dialog.component.scss',
})
export class OverviewFormsUpdateDialogComponent {
  form_id: string = '';
  form_name: string = '';

  public clearButtonDisabled: boolean = true;

  constructor(public ref: DynamicDialogRef) {}

  update() {
    this.ref.close({
      form_id: this.form_id,
      form_name: this.form_name,
    });
  }

  close() {
    this.ref.close(false);
  }
}
