import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { JsonPipe } from '@angular/common';

@Component({
  selector: 'app-overview-deals-stats-renderer',
  imports: [JsonPipe],
  templateUrl: './overview-deals-stats-renderer.component.html',
  styleUrl: './overview-deals-stats-renderer.component.scss',
})
export class OverviewDealsStatsRendererComponent {
  private params: any;
  public value: any;
  public avgVal: any = '';

  agInit(params: any): void {
    this.params = params;
    this.value = params?.value ? params?.value[0] : 0;
    this.avgVal = this.value?.total_case_value_sum / this.value?.total_case_value_count;
  }

  refresh(params: ICellRendererParams) {
    this.params = params;
    return true;
  }
}
